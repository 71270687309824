<template>
    <span class="m-auto" style="font-size: 1rem">
      <span v-if="displayValue" variant="default" v-bind:style="{ color: textColor}">{{ displayValue }}</span>
    </span>
</template>

<script>
export default {
  props: ['metrics', 'metricKey', 'size'],
  computed: {
    textColor: function() {
      const status = this.item.status;
      if (status === 'PASS') {
        return '#05CF6A';
      }

      if (status === 'AVERAGE') {
        return '#FFA400';
      }

      return '#FF4E42';
    },
    item() {
      return _.find(this.metrics, { 'id': this.metricKey }, {});
    },
    displayValue() {
      return this.item.displayValue
    }

  }
};
</script>

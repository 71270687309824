<template>
  <container-wrapper>
    <template slot="title">
      {{ project.name || '' }}
    </template>
    <b-card header="Edit Project">
      <b-card-text>
        <span v-if="loading"><b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>Loading</span>
        <add-project-form v-if="!loading" :project="project" @success="onSubmit" @failed="onFailed"></add-project-form>
      </b-card-text>
    </b-card>
  </container-wrapper>
</template>

<script>
import AddProjectForm from '../../components/forms/AddProjectForm'
import ContainerWrapper from "@/views/Keywords/pages/Container"
import { Http } from "@services"

export default {
  data () {
    return {
      loading: false,
      project: {}
    }
  },
  components: {
    ContainerWrapper,
    AddProjectForm
  },
  async mounted () {
    this.loading = true;
    const { data } = await Http.get(`/keywords/projects/${ this.$route.params.project }`);
    this.project = data;
    this.loading = false;
  },
  methods: {
    onSubmit: function (data) {
      setTimeout(() => {
        this.$router.push({ name: 'projects.index', params: { project: data.id } });
      }, 1000);
    },
    onFailed: function (errors) {
    }
  }
}
</script>

import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faPauseCircle,
  faPlayCircle,
  faQuestionCircle,
  faSpinner,
  faCheck,
  faTimes,
  faQuestion,
  faPencilAlt,
  faSignal,
  faCog,
  faTrash,
  faDownload,
  faChevronUp,
  faChevronDown
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faPauseCircle, faPlayCircle, faSpinner, faQuestionCircle, faCheck, faTimes, faQuestion, faPencilAlt, faSignal, faCog, faTrash, faDownload, faChevronUp, faChevronDown)

Vue.component('font-awesome-icon', FontAwesomeIcon)

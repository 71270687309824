<template>
  <container-wrapper>
    <template slot="title">
      Add Keywords
    </template>
    <b-card header="Add Keywords">
      <b-card-text>
        <add-keywords-form :input-keywords="inputKeywords" @success="onSubmit" @failed="onFailed"></add-keywords-form>
      </b-card-text>
    </b-card>
  </container-wrapper>
</template>

<script>
import AddKeywordsForm from '../../components/forms/AddKeywordsForm'
import ContainerWrapper from "@/views/Keywords/pages/Container"

export default {
  props: ['inputKeywords'],
  components: {
    ContainerWrapper,
    AddKeywordsForm
  },
  methods: {
    onSubmit: function (success) {
      setTimeout(() => {
        this.$router.push({ name: 'projects.index', params: { project: this.$route.params.project } });
      }, 1000);
    },
    onFailed: function (errors) {
    }
  }
}
</script>

<template>
  <container-wrapper>
    <template slot="title">
      {{ project ? project.name : '' }}
    </template>
    <b-row class="text-center">
      <b-col lg="3" md="3">
        <card no-body>
          <b-card-body>
          <h3 class="card-title mb-0">Performance</h3>
          <b-row class="mt-2 mb-3 text-center">
            <b-col>
              <h2><score-badge size="xl" :value="aggregates.desktop.performance_score"></score-badge></h2>
              <small>Desktop</small>
            </b-col>
            <b-col>
              <h2><score-badge size="xl" :value="aggregates.mobile.performance_score"></score-badge></h2>
              <small>Mobile</small>
            </b-col>
          </b-row>
          </b-card-body>
          <score-chart :data="trends" chart-key="performance_score"></score-chart>
        </card>
      </b-col>
      <b-col lg="3" md="3">
        <card no-body>
          <b-card-body>
            <h3 class="card-title mb-0">Accessibility</h3>
            <b-row class="mt-2 mb-3 text-center">
              <b-col>
                <h2><score-badge size="xl" :value="aggregates.desktop.accessibility_score"></score-badge></h2>
                <small>Desktop</small>
              </b-col>
              <b-col>
                <h2><score-badge size="xl" :value="aggregates.mobile.accessibility_score"></score-badge></h2>
                <small>Mobile</small>
              </b-col>
            </b-row>
          </b-card-body>
          <score-chart :data="trends" chart-key="accessibility_score"></score-chart>
        </card>
      </b-col>
      <b-col lg="3" md="3">
        <card no-body>
          <b-card-body>
            <h3 class="card-title mb-0">Best Practices</h3>
            <b-row class="mt-2 mb-3 text-center">
              <b-col>
                <h2><score-badge size="xl" :value="aggregates.desktop.best_practices_score"></score-badge></h2>
                <small>Desktop</small>
              </b-col>
              <b-col>
                <h2><score-badge size="xl" :value="aggregates.mobile.best_practices_score"></score-badge></h2>
                <small>Mobile</small>
              </b-col>
            </b-row>
          </b-card-body>
          <score-chart :data="trends" chart-key="best_practices_score"></score-chart>
        </card>
      </b-col>
      <b-col lg="3" md="3">
        <card no-body>
          <b-card-body>
            <h3 class="card-title mb-0">SEO</h3>
            <b-row class="mt-2 mb-3 text-center">
              <b-col>
                <h2><score-badge size="xl" :value="aggregates.desktop.seo_score"></score-badge></h2>
                <small>Desktop</small>
              </b-col>
              <b-col>
                <h2><score-badge size="xl" :value="aggregates.mobile.seo_score"></score-badge></h2>
                <small>Mobile</small>
              </b-col>
            </b-row>
          </b-card-body>
          <score-chart :data="trends" chart-key="seo_score"></score-chart>
        </card>
      </b-col>
    </b-row>
    <b-card no-body>
      <b-tabs pills card>
        <b-tab title="Scores" active no-body>
          <div class="ml-3 mb-3">
            <small class="mr-2">Options</small>
            <b-button class="mr-2" size="sm" variant="outline-primary" @click="exportUrls">
              <font-awesome-icon :icon="['fas', 'download']"/> Export
            </b-button>
          </div>
          <b-table
            thead-tr-class="thead-light"
            :responsive="true"
            striped hover
            :items="urls"
            :fields="fields"
            :busy="loading"
            sort-by="url"
          >
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #thead-top="data">
              <b-tr>
                <b-th colspan="1"><span class="sr-only">URL</span></b-th>
                <b-th variant="secondary" colspan="5">Mobile Scores</b-th>
                <b-th variant="secondary" colspan="5">Desktop Scores</b-th>
              </b-tr>
            </template>
            <template v-slot:cell(url)="data">
              <a :href="data.item.url" target="_blank" :title="data.item.url">
                {{ data.item.url | midTruncate(65) }}
              </a>
            </template>

            <template v-slot:cell(latest_mobile_result.performance_score)="data">
              <score-badge :model="data.item" device="mobile" type="performance"></score-badge>
            </template>

            <template v-slot:cell(latest_mobile_result.accessibility_score)="data">
              <score-badge :model="data.item" device="mobile" type="accessibility"></score-badge>
            </template>

            <template v-slot:cell(latest_mobile_result.best_practices_score)="data">
              <score-badge :model="data.item" device="mobile" type="best_practices"></score-badge>
            </template>

            <template v-slot:cell(latest_mobile_result.seo_score)="data">
              <score-badge :model="data.item" device="mobile" type="seo"></score-badge>
            </template>

            <template v-slot:cell(mobile_link)="data">
          <span v-b-tooltip.hover title="View Lighthouse Report">
            <router-link  v-if="data.item.latest_mobile_result" :to="{ name: 'pageexperience.url.report', params: { url: data.item.id , strategy: 'mobile'}}"><i class="ni ni-collection"></i></router-link>
          </span>
            </template>

            <template v-slot:cell(latest_desktop_result.performance_score)="data">
              <score-badge :model="data.item" device="desktop" type="performance"></score-badge>
            </template>

            <template v-slot:cell(latest_desktop_result.accessibility_score)="data">
              <score-badge :model="data.item" device="desktop" type="accessibility"></score-badge>
            </template>

            <template v-slot:cell(latest_desktop_result.best_practices_score)="data">
              <score-badge :model="data.item" device="desktop" type="best_practices"></score-badge>
            </template>

            <template v-slot:cell(latest_desktop_result.seo_score)="data">
              <score-badge :model="data.item" device="desktop" type="seo"></score-badge>
            </template>

            <template v-slot:cell(desktop_link)="data">
          <span v-b-tooltip.hover title="View Lighthouse Report">
            <router-link v-if="data.item.latest_desktop_result" :to="{ name: 'pageexperience.url.report', params: { url: data.item.id , strategy: 'desktop'}}" target="_blank"><i class="ni ni-collection"></i></router-link>
          </span>
            </template>

            <template v-slot:cell(options)="data">
              <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret right>
                <template v-slot:button-content>
                  <font-awesome-icon :icon="['fas', 'cog']"/>
                  <span class="sr-only">Settings</span>
                </template>
                <b-dropdown-item-button class="small" variant="danger" @click="deleteUrl(data.item)">
                  <font-awesome-icon :icon="['fas', 'trash']"/>
                  Delete
                </b-dropdown-item-button>
              </b-dropdown>
            </template>
          </b-table>
        </b-tab>
        <b-tab title="Speed" no-body>
          <div class="ml-3 mb-3">
            <small class="mr-2">Options</small>
            <b-button class="mr-2" size="sm" variant="outline-primary" @click="exportUrls">
              <font-awesome-icon :icon="['fas', 'download']"/> Export
            </b-button>
          </div>
          <b-table
            thead-tr-class="thead-light"
            :responsive="true"
            striped hover
            :items="urls"
            :fields="metricFields"
            :busy="loading"
            sort-by="url"
          >
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #thead-top="data">
              <b-tr>
                <b-th colspan="1"><span class="sr-only">URL</span></b-th>
                <b-th variant="secondary" colspan="7">Mobile Scores</b-th>
                <b-th variant="secondary" colspan="7">Desktop Scores</b-th>
              </b-tr>
            </template>
            <template v-slot:cell(url)="data">
              <a :href="data.item.url" target="_blank" :title="data.item.url">
                {{ data.item.url | midTruncate(65) }}
              </a>
            </template>

            <template #head()="data">
              <span :id="`tooltip-${data.column}`" >{{ data.label }}</span>
              <b-tooltip :show="true" :target="`tooltip-${data.column}`">{{ data.field.tooltip }}</b-tooltip>
            </template>

            <template v-slot:cell(mobile_fcp)="data">
              <metric-value :metrics="data.item.latest_mobile_result.metrics" metric-key="first-contentful-paint"></metric-value>
            </template>

            <template v-slot:cell(mobile_si)="data">
              <metric-value :metrics="data.item.latest_mobile_result.metrics" metric-key="speed-index"></metric-value>
            </template>

            <template v-slot:cell(mobile_lcp)="data">
              <metric-value :metrics="data.item.latest_mobile_result.metrics" metric-key="largest-contentful-paint"></metric-value>
            </template>

            <template v-slot:cell(mobile_tti)="data">
              <metric-value :metrics="data.item.latest_mobile_result.metrics" metric-key="interactive"></metric-value>
            </template>

            <template v-slot:cell(mobile_tbt)="data">
              <metric-value :metrics="data.item.latest_mobile_result.metrics" metric-key="total-blocking-time"></metric-value>
            </template>

            <template v-slot:cell(mobile_cls)="data">
              <metric-value :metrics="data.item.latest_mobile_result.metrics" metric-key="cumulative-layout-shift"></metric-value>
            </template>

            <template v-slot:cell(desktop_fcp)="data">
              <metric-value :metrics="data.item.latest_desktop_result.metrics" metric-key="first-contentful-paint"></metric-value>
            </template>

            <template v-slot:cell(desktop_si)="data">
              <metric-value :metrics="data.item.latest_desktop_result.metrics" metric-key="speed-index"></metric-value>
            </template>

            <template v-slot:cell(desktop_lcp)="data">
              <metric-value :metrics="data.item.latest_desktop_result.metrics" metric-key="largest-contentful-paint"></metric-value>
            </template>

            <template v-slot:cell(desktop_tti)="data">
              <metric-value :metrics="data.item.latest_desktop_result.metrics" metric-key="interactive"></metric-value>
            </template>

            <template v-slot:cell(desktop_tbt)="data">
              <metric-value :metrics="data.item.latest_desktop_result.metrics" metric-key="total-blocking-time"></metric-value>
            </template>

            <template v-slot:cell(desktop_cls)="data">
              <metric-value :metrics="data.item.latest_desktop_result.metrics" metric-key="cumulative-layout-shift"></metric-value>
            </template>


            <template v-slot:cell(mobile_link)="data">
              <span v-b-tooltip.hover title="View Lighthouse Report">
                <router-link  v-if="data.item.latest_mobile_result" :to="{ name: 'pageexperience.url.report', params: { url: data.item.id , strategy: 'mobile'}}"><i class="ni ni-collection"></i></router-link>
              </span>
            </template>

            <template v-slot:cell(desktop_link)="data">
              <span v-b-tooltip.hover title="View Lighthouse Report">
                <router-link v-if="data.item.latest_desktop_result" :to="{ name: 'pageexperience.url.report', params: { url: data.item.id , strategy: 'desktop'}}" target="_blank"><i class="ni ni-collection"></i></router-link>
              </span>
            </template>

            <template v-slot:cell(options)="data">
              <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret right>
                <template v-slot:button-content>
                  <font-awesome-icon :icon="['fas', 'cog']"/>
                  <span class="sr-only">Settings</span>
                </template>
                <b-dropdown-item-button class="small" variant="danger" @click="deleteUrl(data.item)">
                  <font-awesome-icon :icon="['fas', 'trash']"/>
                  Delete
                </b-dropdown-item-button>
              </b-dropdown>
            </template>
          </b-table>
        </b-tab>
        <b-tab title="Opportunities" no-body>
          <div class="ml-3 mb-3">
            <small class="mr-2">Options</small>
            <b-button class="mr-2" size="sm" variant="outline-primary" @click="exportOpportunities">
              <font-awesome-icon :icon="['fas', 'download']"/> Export Opportunities
            </b-button>
            <b-button class="mr-2" size="sm" variant="outline-primary" @click="exportUrlOpportunities">
              <font-awesome-icon :icon="['fas', 'download']"/> Export Detailed Opportunities
            </b-button>
          </div>
          <b-card-body>
            <b-form-radio-group
              v-model="opportunityType"
              :options="[{ text: 'Mobile', value: 'mobile'}, {text: 'Desktop', value: 'desktop'}]"
              buttons
              button-variant="outline-primary"
              size="sm"
            ></b-form-radio-group>
          </b-card-body>
          <b-table
            thead-tr-class="thead-light"
            :responsive="true"
            striped hover
            :items="opportunities[opportunityType]"
            :fields="opportunityFields"
            :busy="loading"
            sort-by="impact_score"
            :sort-desc="true"
          >

            <template v-slot:cell(description)="{ value, item }">
              <a v-if="extractLink(value)" :href="extractLink(value)" target="_blank">{{ item.title }}</a>
            </template>

            <template v-slot:cell(category)="{ value }">
              <span v-if="value === 'seo'">SEO</span>
              <span v-else-if="value === 'best-practices'">Best Practices</span>
              <span v-else>{{ value | titleCase }}</span>
            </template>

            <template v-slot:cell(impact_score)="{ value }">
              <div style="font-size: 1rem !important;">
                <b-badge v-if="value === 3" variant="danger">High</b-badge>
                <b-badge v-if="value === 2" variant="warning">Medium</b-badge>
                <b-badge v-if="value === 1" variant="primary">Low</b-badge>
              </div>
            </template>

            <template v-slot:cell(avg_byte_savings)="{ value }">
              <span v-if="!value">-</span>
              <span v-else>{{ Number(value) | prettyBytes }}</span>
            </template>

            <template v-slot:cell(avg_ms_savings)="{ value }">
              <span v-if="!value">-</span>
              <span v-else>{{ Number(value) | prettyMs }}</span>
            </template>
          </b-table>
        </b-tab>
        <template #tabs-end>
          <b-button class="ml-auto" variant="outline-primary" :to="{ name: 'pageexperience.projects.add-urls', params: { project: project ? project.id : '' }}">+ Add Url(s)</b-button>
        </template>
      </b-tabs>
    </b-card>

  </container-wrapper>
</template>

<script>
import ContainerWrapper from '@/views/Keywords/pages/Container';
import { Http, Realtime } from '@services';
import _ from 'lodash';
import ScoreBadge from '@/views/PageExperience/ScoreBadge';
import MetricValue from '@/views/PageExperience/MetricValue';
import LineChart from '@/components/Charts/LineChart';
import * as chartConfigs from '@/components/Charts/config';
import ScoreChart from '@/views/PageExperience/ScoreChart';
import { slugify } from '@utils';

export default {
  name: 'project',
  components: { ScoreChart, LineChart, MetricValue, ScoreBadge, ContainerWrapper },
  data() {
    return {
      metricFields: [
        { key: 'url', label: 'URL', sortable: true },
        { key: 'mobile_fcp', label: 'FCP', tooltip: 'First Contentful Paint marks the time at which the first text or image is painted.' },
        { key: 'mobile_tti', label: 'TTI', tooltip: 'Time to interactive is the amount of time it takes for the page to become fully interactive.' },
        { key: 'mobile_si', label: 'SI', tooltip: 'Speed Index shows how quickly the contents of a page are visibly populated.' },
        { key: 'mobile_tbt', label: 'TBT', tooltip: 'Sum of all time periods between FCP and Time to Interactive, when task length exceeded 50ms, expressed in milliseconds.' },
        { key: 'mobile_lcp', label: 'LCP', tooltip: 'Largest Contentful Paint marks the time at which the largest text or image is painted.'},
        { key: 'mobile_cls', label: 'CLS', tooltip: 'Cumulative Layout Shift measures the movement of visible elements within the viewport.'},
        { key: 'mobile_link', label: '' },
        { key: 'desktop_fcp', label: 'FCP', tooltip: 'First Contentful Paint marks the time at which the first text or image is painted.' },
        { key: 'desktop_tti', label: 'TTI', tooltip: 'Time to interactive is the amount of time it takes for the page to become fully interactive.' },
        { key: 'desktop_si', label: 'SI', tooltip: 'Speed Index shows how quickly the contents of a page are visibly populated.' },
        { key: 'desktop_tbt', label: 'TBT', tooltip: 'Sum of all time periods between FCP and Time to Interactive, when task length exceeded 50ms, expressed in milliseconds.' },
        { key: 'desktop_lcp', label: 'LCP', tooltip: 'Largest Contentful Paint marks the time at which the largest text or image is painted.' },
        { key: 'desktop_cls', label: 'CLS', tooltip: 'Cumulative Layout Shift measures the movement of visible elements within the viewport.' },
        { key: 'desktop_link', label: '' },
        { key: 'options', label: 'Options', class:'th-fit', tdClass: ['p-0', 'pl-4', 'align-middle'] }
      ],
      opportunityFields: [
        { key: 'count', label: '# URLs', sortable: true, class: 'th-fit'  },
        { key: 'impact_score', label: 'Impact', sortable: true, class: 'th-fit' },
        { key: 'category', label: 'Category', sortable: true, class: 'th-fit' },
        { key: 'title', label: 'Audit', sortable: true, class: 'allow-wrap' },
        { key: 'description', label: 'Guide', sortable: true, class: 'allow-wrap' },
        { key: 'avg_ms_savings', label: 'Avg ms savings', sortable: true, class: 'th-fit'  },
        { key: 'avg_byte_savings', label: 'Avg byte savings', sortable: true, class: 'th-fit'  },
      ],
      fields: [
        { key: 'url', label: 'URL', tdClass: ['align-middle'], sortable: true},
        { key: 'latest_mobile_result.performance_score', label: 'Performance', class:'th-fit', sortable: true },
        { key: 'latest_mobile_result.accessibility_score', label: 'Accessibility', class:'th-fit', sortable: true },
        { key: 'latest_mobile_result.best_practices_score', label: 'Best Practices', class:'th-fit', sortable: true },
        { key: 'latest_mobile_result.seo_score', label: 'SEO', class:'th-fit', sortable: true },
        { key: 'mobile_link', label: '', tdClass: ['align-middle'], class:'th-fit' },
        { key: 'latest_desktop_result.performance_score', label: 'Performance', class:'th-fit', sortable: true },
        { key: 'latest_desktop_result.accessibility_score', label: 'Accessibility', class:'th-fit', sortable: true },
        { key: 'latest_desktop_result.best_practices_score', label: 'Best Practices', class:'th-fit', sortable: true },
        { key: 'latest_desktop_result.seo_score', label: 'SEO', class:'th-fit', sortable: true},
        { key: 'desktop_link', label: '', class:'th-fit', tdClass: ['align-middle'] },
        { key: 'options', label: 'Options', class:'th-fit', tdClass: ['p-0', 'pl-4', 'align-middle'] }
      ],
      project: null,
      opportunityType: 'mobile',
      opportunities: {
        mobile: [],
        desktop: []
      },
      trends: {
        mobile: [],
        desktop: []
      },
      urls: [],
      loading: true,
      aggregates: {
        'mobile': [],
        'desktop': []
      }
    };
  },
  created () {
    const debounceLoad = _.debounce(() => this.loadData(false), 300);
    Realtime.subscribe(`PageExperience.Project.${this.$route.params.project}`, {
      'url.updated': debounceLoad,
    });
  },
  beforeDestroy() {
    Realtime.unsubscribe(`PageExperience.Project.${this.$route.params.project}`);
  },
  async mounted() {
    await this.loadData();
    this.loading = false
  },
  methods: {
    async exportUrls() {
      const url = `/page-experience/projects/${ this.$route.params.project }/urls/export`;
      Http.download(url, `export-urls-${ slugify(this.project.name) }.csv`);
    },
    async exportOpportunities() {
      const url = `/page-experience/projects/${ this.$route.params.project }/export-opportunities`;
      Http.download(url, `export-opportunities-${ slugify(this.project.name) }.csv`);
    },
    async exportUrlOpportunities() {
      const url = `/page-experience/projects/${ this.$route.params.project }/export-url-opportunities`;
      Http.download(url, `export-url-opportunities-${ slugify(this.project.name) }.csv`);
    },
    async loadData() {
      let projectData = await Http.get(`/page-experience/projects/${this.$route.params.project}`);
      this.project = projectData.data;
      this.opportunities.mobile = this.project.mobile_opportunities;
      this.opportunities.desktop = this.project.desktop_opportunities;

      this.aggregates = await Http.get(`/page-experience/projects/${this.$route.params.project}/aggregates`);
      this.trends = await Http.get(`/page-experience/projects/${this.$route.params.project}/trends`);

      const { data } = await Http.get(`/page-experience/projects/${this.$route.params.project}/urls`)
      this.urls = data;
    },
    async deleteUrl(url) {
      await Http.delete(`/page-experience/url/${url.id}`);
      await this.loadData();
    },
    extractLink(value) {
      const regex = /^(.+) \[Learn more\]\((.+)\)\.?\s*$/
      let match = regex.exec(value);

      return match ? match[2] : null;
    }
  },

  filters: {
     midTruncate(fullStr, strLen, separator) {
      if (fullStr.length <= strLen) return fullStr;

      separator = separator || '...';

      var sepLen = separator.length,
        charsToShow = strLen - sepLen,
        frontChars = Math.ceil(charsToShow/2),
        backChars = Math.floor(charsToShow/2);

      return fullStr.substr(0, frontChars) +
        separator +
        fullStr.substr(fullStr.length - backChars);
    },
    roundNum(value, accuracy, keep) {
      if (!value) {
        return '-';
      }

      let fixed = Number(value).toFixed(accuracy);

      return keep ? fixed : +fixed;
    }
  }
};
</script>

<style scoped>
  /deep/.allow-wrap{
   white-space: normal !important;
 }
  .chartWrapper {
    height: 150px
  }
</style>

import Echo from 'laravel-echo'
import Http from './Http'

// Make Pusher globally accessible for Laravel Echo.
window.Pusher = require('pusher-js');

const echo = new Echo({
  broadcaster: 'pusher',
  cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
  encrypted: true,
  key: process.env.VUE_APP_PUSHER_APP_KEY,
  authorizer: (channel, options) => {
    return {
      authorize: (socketId, callback) => {
        Http.post('/broadcasting/auth', {
          socket_id: socketId,
          channel_name: channel.name
        }).then( (data) => {
            callback(false, data);
          })
          .catch(error => {
            callback(true, error);
          });
      }
    };
  },
})

export default {
  subscribe (channel, listeners, publicChannel = false) {
    channel = publicChannel
      ? echo.channel(channel)
      : echo.private(channel)

    Object.keys(listeners).forEach(function (event) {
      channel.listen(`.${event}`, listeners[event])
    })
  },

  unsubscribe (channel) {
    echo.leave(channel)
  },
}

<template>
    <span>
        <b-badge variant="default" v-bind:style="{ backgroundColor: backgroundColor}">{{ score }}</b-badge>
    </span>
</template>

<script>
const scoreThresholds = [
  { score: 14, color: '#1ca005' },
  { score: 29, color: '#aada2b' },
  { score: 49, color: '#ffbe02' },
  { score: 69, color: '#ef7a24' },
  { score: 84, color: '#bd462f' },
  { score: 100,color: '#892716' }
];
export default {
    props: ['score'],
    computed: {
        backgroundColor: function () {
          return _.get(_.find(scoreThresholds, o => this.score <= o.score), 'color', '#000');
        }
    }
}
</script>

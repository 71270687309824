<template>
  <div>
    <div class="ml-3 mb-3">
      <small class="mr-2">Options</small>
      <b-dropdown v-if="selectedItems.length > 0" id="dropdown-left" text="With selected..."
                  variant="outline-primary" size="sm" class="mr-2 my-auto">
        <b-dropdown-item class="small" @click="exportSelected">Export</b-dropdown-item>
        <b-dropdown-item-button variant="danger" class="small" @click="deleteSelected">
          <font-awesome-icon :icon="['fas', 'trash']"/> Delete
        </b-dropdown-item-button>
      </b-dropdown>
      <b-button class="mr-2" size="sm" variant="outline-primary" @click="exportAll">
        <font-awesome-icon :icon="['fas', 'download']"/> Export
      </b-button>
    </div>
  <b-table thead-tr-class="thead-light" :responsive="true" striped hover :items="items" :fields="fields"
           :busy="loading">
    <template v-slot:table-busy>
      <div class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong>Loading...</strong>
      </div>
    </template>

    <!-- Checkboxes -->
    <template v-slot:head(index)="data">
      <b-form-checkbox @change="selectAll" :checked="selectedItems.length === items.length"></b-form-checkbox>
    </template>
    <template v-slot:cell(index)="data">
      <b-form-checkbox name="selected-items" v-model="selectedItems" :value="data.item">
      </b-form-checkbox>
    </template>
    <!-- End Checkboxes -->

    <template v-slot:cell(keyword)="data">
      <a @click.prevent="data.item.detailType='sr'; data.toggleDetails()">{{ data.item.keyword }}</a>
      <b-badge href="#" size="sm" @click="data.item.detailType='sr'; data.toggleDetails()" class="ml-1"
               variant="muted">
        <span v-if="data.detailsShowing && data.item.detailType==='sr'"><font-awesome-icon icon="chevron-down"/></span>
        <span v-else><font-awesome-icon icon="chevron-up"/></span>
      </b-badge>
      <b-badge variant="primary" v-if="data.item.keyword_metrics.is_seasonal">Seasonal</b-badge>
    </template>
    <template v-slot:cell(keyword_metrics.search_volume)="data">
      <span v-if="data.item.keyword_metrics.search_volume !== null">
        <b-badge href="#" variant="primary" @click.prevent="data.item.detailType='sv'; data.toggleDetails()">
          <font-awesome-icon icon="signal"/>
        </b-badge>
        {{ data.item.keyword_metrics.search_volume | number('0,0') }}
      </span>
      <span v-else>
        n/a
      </span>
    </template>
    <template v-slot:cell(keyword_metrics.avg_content_length)="data">
      {{ Number(data.item.keyword_metrics.avg_content_length) | number('0,0') }}
    </template>
    <template v-slot:cell(keyword_metrics.cpc)="data">
      <span v-if="data.item.keyword_metrics.cpc !== null">
      {{ Number(data.item.keyword_metrics.cpc) | currency('£') }}
      </span>
      <span v-else>
        n/a
      </span>
    </template>
    <template v-slot:cell(keyword_metrics.ads_competition)="data">
      <span v-if="data.item.keyword_metrics.ads_competition !== null">
        <b-progress :max="100" :value="data.item.keyword_metrics.ads_competition * 100"></b-progress>
      </span>
      <span v-else>
        n/a
      </span>
    </template>
    <template v-slot:cell(keyword_metrics.intent)="data">
      <intent-badge :intent="data.item.keyword_metrics.intent"></intent-badge>
    </template>
    <template v-slot:cell(keyword_metrics.difficulty)="data">
      <difficulty-badge :score="data.item.keyword_metrics.difficulty"></difficulty-badge>
    </template>
    <template v-slot:cell(keyword_metrics.content_difficulty)="data">
      <difficulty-badge :score="data.item.keyword_metrics.content_difficulty"></difficulty-badge>
    </template>
    <template v-slot:cell(keyword_metrics.domain_difficulty)="data">
      <difficulty-badge :score="data.item.keyword_metrics.domain_difficulty"></difficulty-badge>
    </template>

    <template v-slot:head(keyword_metrics.competitor_relevance)="data">
      <span v-b-tooltip.hover
            title="A percentage of search results taken by competitors you've added to this project.">
        Competitor Relevance
      </span>
    </template>
    <template v-slot:cell(keyword_metrics.competitor_relevance)="data">
      <b-progress :max="100" :value="data.item.keyword_metrics.competitor_relevance * 100"
                  v-b-tooltip.hover :title="$options.filters.percent(data.item.keyword_metrics.competitor_relevance)"
      ></b-progress>
    </template>
    <template v-slot:row-details="row">
      <b-card>
        <div v-if="row.item.detailType === 'sr'">
          <search-results-table :project="project" :keyword="row.item"></search-results-table>
        </div>
        <div style="height:350px" v-if="row.item.detailType === 'sv'">
          <search-volume-chart v-if="row.item.detailType === 'sv'" style="height: 340px"
                               :search-volumes="row.item.keyword_metrics.historic_volumes"></search-volume-chart>
        </div>
      </b-card>
    </template>
  </b-table>
  </div>
</template>
<script>
import DifficultyBadge from '../components/DifficultyBadge'
import IntentBadge from '../components/IntentBadge'
import SearchResultsTable from "../components/tables/SearchResultsTable"
import SearchVolumeChart from "../components/charts/SearchVolumeChart"
import { Alert, Http, Realtime } from "@services"
import { slugify } from "@utils"
import _ from 'lodash'
export default {
  name: 'keyword-overview',
  components: { DifficultyBadge, IntentBadge, SearchResultsTable, SearchVolumeChart },
  props: {
    project: {},
  },
  data () {
    return {
      items: [],
      selectedItems: [],
      fields: [
        { key: 'index', class: 'th-fit' },
        { key: 'keyword', label: 'Keyword', sortable: true, sortDirection: 'asc' },
        { key: 'keyword_metrics.search_volume', label: 'Volume', sortable: true, class: 'th-fit' },
        { key: 'keyword_metrics.cpc', label: 'Avg. CPC', sortable: true, class: 'th-fit' },
        { key: 'keyword_metrics.ads_competition', label: 'Ad Comp.', sortable: true, class: 'th-fit align-middle' },
        { key: 'keyword_metrics.avg_content_length', label: 'Avg. Content Length', sortable: true, class: 'th-fit text-center' },
        { key: 'keyword_metrics.intent', label: 'Intent', sortable: true, class: 'th-fit text-center' },
        { key: 'keyword_metrics.difficulty', label: 'Difficulty', sortable: true, class: 'th-fit text-center' },
        { key: 'keyword_metrics.content_difficulty', label: 'Content Difficulty', sortable: true, class: 'th-fit text-center' },
        { key: 'keyword_metrics.domain_difficulty', label: 'Domain Difficulty', sortable: true, class: 'th-fit text-center' },
        { key: 'keyword_metrics.competitor_relevance', label: 'Competitor Relevance', sortable: true, class: 'th-fit text-center align-middle' }
      ],
      loading: true
    }
  },
  created () {
    const debounceLoad = _.debounce(() => this.loadTableData(false), 300);
    Realtime.subscribe(`Project.${this.project.id}`, {
      'keyword.updated': debounceLoad,
      'project.keywords-updated': debounceLoad
    });
  },
  beforeDestroy() {
    Realtime.unsubscribe(`Project.${this.project.id}`);
  },
  mounted () {
    this.loadTableData()
  },
  methods: {
    loadTableData: async function (loading = true) {
      this.loading = loading || false;
      const { data } = await Http.get(`/keywords/projects/${ this.$route.params.project }/keywords`);

      /**
       * Retain showDetails for open records
       */
      const showDetails = _.cloneDeep(_.filter(this.items, {_showDetails: true}));
      this.items = data;
      if (showDetails.length > 0) {
          _.each(showDetails, (item) => {
            const newItem = _.find(this.items, {id: item.id});
            newItem._showDetails = item._showDetails;
            newItem.detailType = item.detailType;
          })
      }

      this.loading = false;
    },
    exportAll() {
      const url = `/keywords/projects/${ this.project.id }/keywords/export`;
      Http.download(url, `export-${ slugify(this.project.name) }.csv`);
    },
    exportSelected () {
      const ids = _.map(this.selectedItems, 'id');
      const url = `/keywords/projects/${ this.project.id }/keywords/export?keyword_ids=${ _.join(ids, ',') }`
      Http.download(url, `export-${ slugify(this.project.name) }.csv`);
    },
    deleteSelected () {
      let ids = _.map(this.selectedItems, 'id');
      Alert.confirm(async () => {
        await Http.post(`/keywords/projects/${ this.$route.params.project }/keywords/bulk-delete`, { keyword_ids: ids })
        await Alert.success({
          title: "Deleted!",
          text: "Successfully deleted!",
        }).fire();
        return this.loadTableData();
      })
      .catch( async (err) => {
        await Alert.error({
          title: "Error!",
          text: "Delete failed!"
        }).fire();
      });
    },
    selectAll () {
      if (this.selectedItems.length === this.items.length) {
        this.selectedItems = [];
      } else {
        this.selectedItems = this.items.slice();
      }
    },
  }
}
</script>

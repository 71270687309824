<template>
  <container-wrapper>
    <template slot="title">
      Create Project
    </template>
    <b-card header="Add Project">
      <b-card-text>
        <project-form @success="onSubmit" @failed="onFailed"></project-form>
      </b-card-text>
    </b-card>
  </container-wrapper>
</template>

<script>
import ContainerWrapper from "@/views/Keywords/pages/Container"
import ProjectForm from '@/views/PageExperience/ProjectForm';

export default {
  components: {
    ContainerWrapper,
    ProjectForm
  },
  methods: {
    onSubmit: function (data) {
      setTimeout(() => {
        this.$router.push({name: 'pageexperience.projects.index', params: {project: data.id}});
      }, 1000);
    },
    onFailed: function (errors) {
    }
  }
}
</script>

import { Http } from "@services"

export default {
  namespaced: true,

  state: {
    ready: false,
    application: null,
    environment: null,
    status: null,
    roles: [],
    permissions: []
  },

  getters: {
    roles (state) {
      return state.roles
    },
  },

  mutations: {
    SET_READY (state, value) {
      state.ready = value
    },

    SET_DATA (state, data) {
      Object.keys(data).forEach(function (key) {
        state[key] = data[key];
      });
    }
  },

  actions: {
    async refreshState ({ commit }) {
      const data = await Http.get('/cortex')

      if (data.status === 200) {
        commit('SET_READY', true);
        commit('SET_DATA', data);
      } else {
        commit('SET_READY', false);
        commit('SET_DATA', {});
      }
    }
  }
}

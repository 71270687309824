<template>
  <container-wrapper>
    <template slot="title">
      {{ project.name }}
    </template>
    <b-card no-body>
      <b-tabs pills card>
        <template v-slot:tabs-end>
          <div class="ml-auto my-auto">
            <b-button class="pull-right" size="sm" variant="primary"
                      :to="{ name: 'domains.add', params: { project: $route.params.project } }">
              + Add Competitor
            </b-button>
            <b-button class="pull-right mr-2" size="sm" variant="primary"
                      :to="{ name: 'keywords.add', params: { project: $route.params.project } }">
              + Add Keyword(s)
            </b-button>
            <b-button class="pull-right mr-2" size="sm" variant="outline-primary"
                      :to="{ name: 'research.select-domains', params: { project: $route.params.project } }">
              Keyword Research
            </b-button>
          </div>
        </template>
        <b-tab no-body title="Keywords" active>
          <keyword-overview :project="project" v-if="!loading" />
        </b-tab>
        <b-tab title="Domain Rank Overview" no-body>
          <domain-rank-overview v-if="!loading" />
        </b-tab>
      </b-tabs>
    </b-card>
  </container-wrapper>
</template>

<script>
import DomainRankOverview from "./DomainRankOverview"
import ContainerWrapper from "@/views/Keywords/pages/Container"
import { Http } from "@services"
import KeywordOverview from "@/views/Keywords/pages/KeywordOverview"

export default {
  name: "Project",
  components: {
    KeywordOverview,
    ContainerWrapper,
    DomainRankOverview
  },
  data () {
    return {
      loading: true,
      project: {},
    }
  },
  mounted () {
    this.loadProject()
  },

  methods: {
    loadProject: async function () {
      const { data } = await Http.get(`/keywords/projects/${ this.$route.params.project }`);
      this.project = data;
      this.loading = false;
    }
  }
}
</script>


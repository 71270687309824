import Vue from 'vue';

import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';

// router setup
import router from './routes/router';
import store from './store';

// TODO: Move these to a plugin and rename.
require('./Auth');
require('./icons')

// TODO: Refactor the code that needs these forms to not use them then strip the code.
require('./services/Keywords/bootstrap');

// plugin setup
Vue.use(DashboardPlugin);

import Vue2Filters from 'vue2-filters'
Vue.use(Vue2Filters)


/* eslint-disable no-new */
store.dispatch('global/refreshState');
store.dispatch('auth/refreshUser').then(() => {
  new Vue({
    el: '#app',
    render: h => h(App),
    router,
    store
  })
});

<template>
  <div>
    <base-header class="pb-9">
      <b-row align-v="center" class="py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0">
            <slot name="title">{{ $route.name }}</slot>
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--9">
      <b-row class="justify-content-center">
        <b-col lg="12" sm="12" md="10" class="card-wrapper">
          <slot/>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  name: 'container-wrapper'
}
</script>
<style>
table.table thead tr th {
  vertical-align: middle;
}

thead tr th.th-fit {
  white-space: normal;
}

.th-fit {
  white-space: nowrap;
  width: 1%;
}
</style>

<template>
  <b-table thead-tr-class="thead-light" small :items="keywordData" :fields="fields">
    <template v-slot:cell(url)="data">
      <a :href="data.item.url">{{ data.item.url }}</a>
    </template>
  </b-table>
</template>
<script>
import { Http } from "@services"

export default {
  name: 'view-keyword-details',
  props: {
    keywordId: {},
  },
  data () {
    return {
      keywordData: [],
      fields: [
        { key: 'rank', class: 'th-fit p-1 align-middle' },
        { key: 'etv', label: 'Estimated Traffic', class: 'th-fit' },
        { key: 'url' },
      ],
    }
  },
  async mounted () {
    let url = `/keywords/projects/${ this.$route.params.project }/research/domain-intersection/${ this.keywordId }`
    let { data } = await Http.get(url);
    this.keywordData = data;
  }
}
</script>

<template>
  <container-wrapper>
    <template slot="title">
      Research: Select Domains
    </template>
    <b-card header="Select Domains">
      <b-card-text>
        <b-form-checkbox
          v-model="allSelected"
          :indeterminate="indeterminate"
          aria-describedby="domains"
          aria-controls="domains"
          @change="toggleAll"
        >
          {{ allSelected ? 'Un-select All' : 'Select All' }}
        </b-form-checkbox>
        <b-list-group>
          <b-list-group-item v-for="(domain, index) in domains" :key="index"
                             class="d-flex justify-content-between align-items-center">
            <b-form-checkbox name="selected-items" v-model="selectedDomains" :value="domain.id"
                             :disabled="domain.domain_research_keywords_count < 1">
              {{ domain.domain }}
            </b-form-checkbox>
            <div>
              <b-spinner small variant="primary" v-if="domain.is_updating"></b-spinner>
              <b-badge variant="primary" pill v-else-if="domain.keywords_updated_at">
                {{ domain.domain_research_keywords_count }} Keywords
              </b-badge>
              <b-badge href="#" size="sm" variant="primary" @click.prevent="syncKeywords(domain.id)" v-else>+ Sync
                Keywords
              </b-badge>
              <b-badge class="ml-2" href="#" size="sm" variant="danger" @click.prevent="removeDomain(domain.id, index)">
                Remove
              </b-badge>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-card-text>
      <b-button @click="getKeywords" variant="primary" :disabled="buttonDisabled">Get Keywords</b-button>
    </b-card>
  </container-wrapper>
</template>

<script>
import ContainerWrapper from "@/views/Keywords/pages/Container"
import { Http, Alert } from "@services"

export default {
  components: { ContainerWrapper },
  data () {
    return {
      selectedDomains: [],
      domains: [],
      timerId: false,
      allSelected: false,
      indeterminate: false
    }
  },
  mounted () {
    this.getDomains().then(response => {
      this.pollWhileUpdating();
    });
  },
  beforeRouteLeave (to, from, next) {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
    next();
  },
  computed: {
    buttonDisabled: function () {
      return !this.selectedDomains.length;
    },
    selectableDomains: function () {
      return _.filter(this.domains, function (domain) {
        return !!(domain.is_updating === false && domain.keywords_updated_at);
      })
    }
  },
  watch: {
    selectedDomains (newVal, oldVal) {
      if (newVal.length === 0) {
        this.indeterminate = false
        this.allSelected = false
      } else if (newVal.length === this.selectableDomains.length) {
        this.indeterminate = false
        this.allSelected = true
      } else {
        this.indeterminate = true
        this.allSelected = false
      }
    }
  },
  methods: {
    async removeDomain (domainId, index) {
      await Alert.confirm(async () => {
        this.domains.splice(index, 1);
        await Http.delete(`/keywords/projects/${ this.$route.params.project }/domains/${ domainId }`);
      });
    },
    toggleAll (checked) {
      this.selectedDomains = checked ? _.map(this.selectableDomains, 'id') : [];
    },
    pollWhileUpdating: function (isCallback) {
      if (this.timerId && !isCallback) {
        return;
      }

      let domains = _.filter(this.domains, { is_updating: true });
      if (!domains.length) {
        this.timerId = false;
        return;
      }

      this.timerId = _.delay(() => {
        this.getDomains().then(this.pollWhileUpdating(true));
      }, 1000);
    },
    syncKeywords: async function (domainId) {
      Http.get(`/keywords/projects/${ this.$route.params.project }/research/domains/${ domainId }/sync-keywords`);
      let domain = _.find(this.domains, function (item) {
        return item.id === domainId;
      });
      if (domain) {
        domain.is_updating = true;
      }

      this.pollWhileUpdating();
    },
    getDomains: function () {
      return Http.get(`/keywords/projects/${ this.$route.params.project }/research/domains`)
        .then(({ data }) => {
          this.domains = data;
        });
    },
    getKeywords () {
      return this.$router.push({
        name: 'research.view-keywords',
        params: { domains: this.selectedDomains.join(',') }
      });
    }
  },
}
</script>

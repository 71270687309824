<template>
    <b-form @submit.prevent="form.submit()">
        <div class="alert alert-success" v-if="form.successful">
            Saved successfully!
        </div>
        <b-alert :show="form.errors.hasErrors()" variant="danger">There was an error processing your input, see below.</b-alert>

      <b-form-group
          label="Client"
          label-for="client_id"
        >
          <b-form-select v-model="form.client_id" id="client_id" :options="clientOptions" :class="{'is-invalid': form.errors.has('client_id')}"></b-form-select>
          <span class="invalid-feedback" v-show="form.errors.has('client_id')">
            {{ form.errors.get('client_id') }}
          </span>
        </b-form-group>

        <b-form-group
            label="Project Name"
            label-for="name"
        >
            <b-form-input placeholder="Project Name" id="name" v-model="form.name" :class="{'is-invalid': form.errors.has('name')}" />
            <span class="invalid-feedback" v-show="form.errors.has('name')">
                    {{ form.errors.get('name') }}
            </span>
        </b-form-group>

        <b-button type="submit" :disabled="form.busy" variant="primary">
            <b-spinner small v-if="form.busy"></b-spinner> Submit
        </b-button>
    </b-form>
</template>

<script>
import _ from 'lodash'
import {Form, Http} from "@services"
export default {
  props: ['project'],
  data() {
    const initialData = {
      name: this.project ? this.project.name : '',
      client_id: this.project ? this.project.client_id : null
    };
    return {
      clientOptions: [
          { value: null, text: 'Please select an option' }
        ],
        form: new Form(initialData, async (form) => {
          const method = this.updating ? 'patch' : 'post'
          const { data } = await Http[method](`/page-experience/projects/${this.$route.params.project || ''}`, form);
          this.$emit('success', data);
        }, ({ errors }) => {
          this.$emit('failed', errors);
        }),
        updating: !!this.project
    }
  },
  async mounted () {
    const { data } = await Http.get('/clients');
    _.forEach(data, (client) => {
      this.clientOptions.push({
        value: client.id,
        text: client.name
      });
    });
  }
}
</script>

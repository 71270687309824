<template>
  <div>
    <div class="p-3 d-flex">
      <b-check class="mr-3" v-model="hideAdded">Hide already added?</b-check>
      <b-check v-model="showHidden">Show hidden domains?</b-check>
    </div>
    <b-table thead-tr-class="thead-light" id="domainKeywordTable" :items="domainKeywordData" :fields="fields">
      <template v-slot:cell(options)="data">
        <div v-if="Number(data.item.is_hidden) >= 1">
          <b-button size="sm" variant="dark" @click="toggleHide(data.item.domain)">Un-hide</b-button>
        </div>
        <div v-else-if="Number(data.item.domain_id) === 0">
          <b-button size="sm" variant="success" @click="addDomain(data.item.domain)">+ Add</b-button>
          <b-button
            v-b-tooltip.hover.bottom title="Hide this competitor from this list to clean up your list - reversible."
            size="sm" variant="dark" @click="toggleHide(data.item.domain)">- Hide</b-button>
        </div>
        <div v-else>
          <b-button size="sm" variant="danger" @click="removeDomain(data.item.domain_id)">Remove</b-button>
        </div>
      </template>
    </b-table>
    <base-pagination align="center" size="sm" v-model="currentPage" :total="meta.total" :per-page="meta.per_page"></base-pagination>
  </div>
</template>
<script>
import _ from 'lodash'
import { Http, Alert } from "@services"

export default {
  name: 'domain-rank-overview',
  data () {
    return {
      hideAdded: false,
      showHidden: false,
      currentPage: 1,
      meta: {},
      domainKeywordData: [],
      fields: ['domain', 'estimated_traffic', 'num_keywords', 'average_position', 'options']
    }
  },
  mounted () {
    this.loadData();
  },
  watch: {
    hideAdded: function () {
      this.loadData();
    },
    currentPage: function () {
      this.loadData();
    },
    showHidden: function() {
      this.loadData();
    }
  },
  methods: {
    loadData: async function () {
      return await Http.get(`/keywords/projects/${ this.$route.params.project }/domain-keywords-count?page=${ this.currentPage }&hide=${ Number(this.hideAdded) }&show_hidden=${Number(this.showHidden)}`)
        .then( ({ data, meta }) => {
          this.domainKeywordData = data;
          this.meta = meta;
        })
    },
    toggleHide: async function(domain) {
      let domainRecord = _.find(this.domainKeywordData, { domain: domain });
      domainRecord.is_hidden = Number(domainRecord.is_hidden) === 0 ? 1 : 0;
      return await Http.post(`/keywords/projects/${ this.$route.params.project}/hidden-domains/toggle`, {
        domain: domain
      });
    },
    removeDomain: async function (domainId) {
      await Alert.confirm(async () => {
        let domainRecord = _.find(this.domainKeywordData, { 'domain_id': domainId });
        domainRecord.domain_id = 0;

        return await Http.delete(`/keywords/projects/${ this.$route.params.project }/domains/${ domainId }`);
      })
    },
    addDomain: async function (domain) {
      let domainRecord = _.find(this.domainKeywordData, { 'domain': domain });
      domainRecord.domain_id = -1;

      return await Http.post(`/keywords/projects/${ this.$route.params.project }/domains`, { domains: domain })
        .then( ({ data }) => {
          domainRecord.domain_id = data[0].id
        });
    },
  }
}
</script>

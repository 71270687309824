import Vue from 'vue'
import acl from 'vue-browser-acl'
import router from './routes/router'
import store from './store'

const User = () => {
  return store.getters['auth/user']
}

Vue.use(acl, User, (acl) => {
  acl.rule('read', (User) => User ? User.hasPermission('read') : false);
  acl.rule('write', (User) => User ? User.hasPermission('write') : false);
  acl.rule('administer', (User) => User ? User.hasPermission('administer') : false);
}, { router })

<template>
  <bread-crumb list-classes="breadcrumb-links breadcrumb-dark">
    <BreadCrumbItem>
        <router-link to="/">
          <i class="fas fa-home"></i>
        </router-link>
    </BreadCrumbItem>
    <BreadCrumbItem
      v-for="(route, index) in routeElements"
      :key="route.name"
      :active="index === routeElements.length - 1"
      style="display:inline-block"
    >
      <router-link
        :to="{ name: route.name }"
        v-if="index < routeElements.length - 1"
      >
        {{ getBreadName(route) }}
      </router-link>
      <span v-else>{{ getBreadName(route) }}</span>
    </BreadCrumbItem>
  </bread-crumb>
</template>

<script>
  import BreadCrumb from './Breadcrumb';
  import BreadCrumbItem from './BreadcrumbItem';

  export default {
    name: 'route-breadcrumb',
    components: {
      BreadCrumb,
      BreadCrumbItem
    },
    methods: {
      getBreadName(route) {
        return route.meta.title || route.name;
      }
    },
    computed: {
      routeElements: function() {
        return this.$route.matched.slice().filter(function(route) {
          return !!route.name;
        });
      }
    }
  };
</script>

<style scoped></style>

<template>
    <span>
        <b-badge variant="default" v-bind:style="{ backgroundColor: backgroundColor}">{{ intent }}</b-badge>
    </span>
</template>

<script>
const badgeColours = [
  { intent: 'research', color: '#1ca005' },
  { intent: 'transactional', color: '#aada2b' },
  { intent: 'local', color: '#aada2b' },
  { intent: 'visual', color: '#ffbe02' },
  { intent: 'video', color: '#ef7a24' },
  { intent: 'fresh', color: '#ef7a24' },
  { intent: 'answer', color: '#bd462f' },
  { intent: 'branded', color: '#892716' }
];
export default {
    props: ['intent'],
    computed: {
        backgroundColor: function () {
          return _.get(_.find(badgeColours, o => this.intent === o.intent ), 'color', '#84919a')
        }
    }
}
</script>

<template>
  <container-wrapper>
    <template slot="title">
      Create Project
    </template>
    <b-card header="Add Project">
      <b-card-text>
        <add-project-form @success="onSubmit" @failed="onFailed"></add-project-form>
      </b-card-text>
    </b-card>
  </container-wrapper>
</template>

<script>
import AddProjectForm from '../../components/forms/AddProjectForm'
import ContainerWrapper from "@/views/Keywords/pages/Container"

export default {
  components: {
    ContainerWrapper,
    AddProjectForm
  },
  methods: {
    onSubmit: function (data) {
      setTimeout(() => {
        this.$router.push({name: 'projects.index', params: {project: data.id}});
      }, 1000);
    },
    onFailed: function (errors) {
    }
  }
}
</script>

/**
 * Load the SparkForm helper class.
 */
import axios from 'axios'
window.axios = axios;
require('./form');

/**
 * Define the SparkFormError collection class.
 */
require('./errors');

/**
 * Add additional HTTP / form helpers to the Spark object.
 */
window.Spark = require('./http');

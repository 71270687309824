import Vue from 'vue';
import VueRouter from 'vue-router';
import examplesRoutes from './examplesRoutes';
import routes from './routes';
import store from '../store'
import _ from 'lodash'

Vue.use(VueRouter);

// configure router
const router = new VueRouter({
  routes: [...examplesRoutes, ...routes],
  linkActiveClass: 'active',
  mode: 'history',
  scrollBehavior: (to, from ,savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  const authenticated = store.state.auth.authenticated;
  const isAuthRoute = to.matched.some(item => item.meta.requiresAuth)
  const isLoginRoute = to.matched.some(item => item.meta.redirectIfAuthed);

  if (isLoginRoute && authenticated) return next({ name: 'Dashboard' });
  if (isAuthRoute && authenticated) return next()
  if (isAuthRoute) return next({ name: 'Login' })
  next()
});

function setPageTitleMiddleware (to, from, next) {
  const pageTitle = _.get(to, 'meta.title')
  const pageName = _.map(_.reverse(to.matched.slice()), 'name').filter(function(el) {
    return !!el;
  }).join(' | ');

  if (pageTitle) {
    window.document.title = pageTitle + ' | ' + process.env.VUE_APP_NAME
  } else {
    window.document.title = pageName + ' | ' + process.env.VUE_APP_NAME;
  }
  next()
}

router.beforeEach(setPageTitleMiddleware)

export default router;

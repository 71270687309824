<template>
  <b-navbar-nav class="align-items-center ml-auto ml-md-0" v-if="authenticated">
    <base-dropdown menu-on-right
                   class="nav-item"
                   tag="li"
                   title-tag="a"
                   title-classes="nav-link pr-0">
      <a href="#" class="nav-link pr-0" @click.prevent slot="title-container">
        <b-media no-body class="align-items-center">
                  <span class="avatar avatar-sm rounded-circle">
                    <img alt="Image placeholder" :src="user.profile_photo_url">
                  </span>
          <b-media-body class="ml-2 d-none d-lg-block">
            <span class="mb-0 text-sm  font-weight-bold">{{ user.name }}</span>
          </b-media-body>
        </b-media>
      </a>
      <template>
        <b-dropdown-header class="noti-title">
          <h6 class="text-overflow m-0">Welcome!</h6>
        </b-dropdown-header>
        <b-dropdown-item :to="{ name: 'Profile' }">
          <i class="ni ni-single-02"></i>
          <span>My profile</span>
        </b-dropdown-item>
        <div class="dropdown-divider"></div>
        <b-dropdown-item href="#" @click.prevent="logout">
          <i class="ni ni-user-run"></i>
          <span>Logout</span>
        </b-dropdown-item>
      </template>
    </base-dropdown>
  </b-navbar-nav>
</template>
<script>
import {mapGetters, mapActions} from "vuex"
export default {
  name: 'dashboard-nav-user-dropdown',
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
    })
  },
  methods: {
    ...mapActions({
      logoutAction: 'auth/logout'
    }),
    async logout () {
      await this.logoutAction()
      await this.$router.replace({ name: 'Login' })
    }
  }
}
</script>

export const slugify = value => value.toLowerCase().replace(/[\s\W-]+/g, '-')
export const capitalize = value => value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
export const titleCase = value => value.replace(/\w[^\s-\_]*/g, capitalize)
export const prettyMs = function (value) {
  if (typeof value !== 'number' || isNaN(value)) {
    throw new TypeError('Expected a number');
  }
  var unit = 'ms';
  if (value > 1000) {
    value = value / 1000;
    unit = 's'
  }

  value = value.toFixed(2) * 1
  return value + ' ' + unit
}
export const prettyBytes = function (num) {
  // jacked from: https://github.com/sindresorhus/pretty-bytes
  if (typeof num !== 'number' || isNaN(num)) {
    throw new TypeError('Expected a number');
  }

  var exponent;
  var unit;
  var neg = num < 0;
  var units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  if (neg) {
    num = -num;
  }

  if (num < 1) {
    return (neg ? '-' : '') + num + ' B';
  }

  exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1);
  num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
  unit = units[exponent];

  return (neg ? '-' : '') + num + ' ' + unit;
}

<template>
    <b-table thead-tr-class="thead-light" striped hover :items="items" :fields="fields" :busy="loading">
        <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
            </div>
        </template>
        <template v-slot:cell(options)="data">
          <div v-if="data.item.is_hidden">
            <b-button size="sm" variant="dark" @click="toggleHideDomain(data.item.domain)">Un-hide Competitor</b-button>
          </div>
          <div v-else-if="data.item.domain_id">
            <b-button size="sm" variant="danger" @click="removeDomain(data.item.domain_id)">Remove competitor</b-button>
          </div>
          <div v-else>
            <b-button size="sm" variant="success" @click="addDomain(data.item.domain)">+ Add Competitor</b-button><br />
            <b-button
              v-b-tooltip.hover.bottom title="This hides the domain from the domain overview tab."
              class="mt-1" size="sm" variant="dark" @click="toggleHideDomain(data.item.domain)">- Hide Competitor</b-button>
          </div>
        </template>
        <template v-slot:cell(search_result)="data">

          <a :href="data.item.url" target="_blank" class="text-default">
            <b>{{data.item.title}}</b> <br />
            {{data.item.url}}<br />
            {{data.item.description}}
          </a>
        </template>

    </b-table>
</template>

<script>
    // import DifficultyBadge from '../components/DifficultyBadge'
    // import IntentBadge from '../components/IntentBadge'
    // import SearchVolumeChart from "../components/charts/SearchVolumeChart"
    import { Alert, Http } from "@services"
    import _ from "lodash"

    export default {
        name: "SearchResultsTable",
        props: {
            project: {
                type: [Object],
                default: function () {
                    return null
                }
            },
            keyword: {
                type: [Object],
                default: function () {
                    return null
                }
            },
        },
        components: {
            // DifficultyBadge, IntentBadge, SearchVolumeChart
        },
        data() {
            return {
                items: [],
                fields: [
                  { key: 'position', label: 'Position', sortable: false, sortDirection: 'asc', class: 'th-fit' },
                  { key: 'organic_position', label: 'Organic Position', sortable: false, class: 'th-fit' },
                  { key: 'search_result', label: 'Search Result', sortable: false, class: 'text-wrap'},
                  { key: 'domain_authority', label: 'Domain Authority', sortable: false, class: 'th-fit' },
                  { key: 'content_score', label: 'Content Score', sortable: false, class: 'th-fit' },
                  { key: 'content_num_words', label: 'Num Words', sortable: false, class: 'th-fit' },
                  { key: 'options', label: 'Options', sortable: false, class:'th-fit' },
                ],
                loading: true
            }
        },
        async mounted() {
            await this.loadTableData()
        },
        methods: {
          loadTableData: async function() {
            this.loading = true;
            const { data } =  await Http.get(`/keywords/projects/${this.project.id}/keywords/${this.keyword.id}/search-results`)
            this.items = data;
            this.loading = false;
          },
          toggleHideDomain: async function(domain) {
            let domainRecord = _.find(this.items, { domain: domain });
            domainRecord.is_hidden = !domainRecord.is_hidden ? 1 : 0;
            return await Http.post(`/keywords/projects/${ this.$route.params.project}/hidden-domains/toggle`, {
              domain: domain
            });
          },
          removeDomain: async function (domainId) {
            await Alert.confirm(async () => {
              let domainRecord = _.find(this.items, { 'domain_id': domainId });
              domainRecord.domain_id = null;

              return await Http.delete(`/keywords/projects/${this.project.id}/domains/${ domainId }`);
            })
          },
          addDomain: async function (domain) {
            let domainRecord = _.find(this.items, { 'domain': domain });
            domainRecord.domain_id = -1;

            return await Http.post(`/keywords/projects/${this.project.id}/domains`, { domains: domain })
              .then( ({ data }) => {
                domainRecord.domain_id = data[0].id
              });
          },
        }
    }
</script>

<style scoped>

</style>

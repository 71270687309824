<template>
  <div>
    <div v-if="loading">
      <b-spinner variant="primary" label="Spinning"></b-spinner> Loading...
    </div>
    <VueLighthouseViewer :json="json" v-if="!loading"/>
  </div>
</template>
<script>
import VueLighthouseViewer from "vue-lighthouse-viewer";
import { Http } from '@services';

export default {
  components: { VueLighthouseViewer },
  data() {
    return {
      json: null,
      loading: true,
      url: null
    };
  },
  async mounted() {
    const { data } = await Http.get(`/page-experience/url/${this.$route.params.url}`)
    this.url = data

    const raw = this.$route.params.strategy === 'mobile' ? data.latest_mobile_json : data.latest_desktop_json
    this.json = JSON.parse(raw).lighthouseResult
    this.loading = false;
  }
}
</script>

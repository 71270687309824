<template>
  <div class="chartWrapper">
    <line-chart
      :height="150"
      :chart-data="chartData"
      :extra-options="options"
    >
    </line-chart>
  </div>
</template>

<script>
import LineChart from '@/components/Charts/LineChart';

export default {
  components: { LineChart },
  props: ['data', 'chartKey'],
  data() {
    return {
      options: {
        scales: {
          xAxes: [
            {
              type: 'time',
              distribution: 'linear',
              time: {
                unit: 'day'
              }
            }
          ],
        },
      },
      // extraOptions: chartConfigs.blueChartOptions,
    };
  },
  computed: {
    chartData() {
      let mobileData = {
        label: 'Mobile',
        data: this.data.mobile.map((item) => {
          return {
            t: item.date,
            y: item[this.chartKey]
          }
        }),
        borderWidth: 1,
        backgroundColor: "rgba(100,100,200,.2)",
        hoverBackgroundColor: "rgba(100,100,200,.9)",
        lineTension: 0,
        pointRadius: 5,
        pointHoverRadius: 8
      };

      let desktopData = {
        label: 'Desktop',
        data: this.data.desktop.map((item) => {
          return {
            t: item.date,
            y: item[this.chartKey]
          }
        }),
        borderWidth: 1,
        backgroundColor: "rgba(100,100,200,.2)",
        hoverBackgroundColor: "rgba(100,100,200,.9)",
        lineTension: 0,
        pointRadius: 5,
        pointHoverRadius: 8
      };

      return {
        datasets: [
          mobileData,
          desktopData,
        ],
      };
    },
  },
};
</script>

<style scoped>

</style>

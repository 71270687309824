<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar>
      <template slot-scope="props" slot="links">
        <sidebar-item
          :link="{
            name: 'Dashboards',
            icon: 'ni ni-shop text-primary',
          }"
        >
          <sidebar-item :link="{ name: 'Dashboard', path: '/' }"></sidebar-item>
        </sidebar-item>
      </template>
      <template slot="links-examples">
        <hr class="my-3">
        <h6 class="navbar-heading p-0 text-muted">Examples</h6>
        <b-navbar-nav>
          <sidebar-item
            :link="{
              name: 'Dashboards',
              icon: 'ni ni-shop text-primary',
            }"
          >
            <sidebar-item
              :link="{ name: 'Dashboard', path: '/examples/dashboard' }"
            ></sidebar-item>
            <sidebar-item
              :link="{ name: 'Alternative', path: '/examples/alternative' }"
            ></sidebar-item>
          </sidebar-item>

          <sidebar-item :link="{
                    name: 'Examples',
                    icon: 'ni ni-ungroup text-orange'
                    }">
            <sidebar-item :link="{ name: 'Pricing', path: '/examples/pricing' }"/>
            <sidebar-item :link="{ name: 'Login', path: '/examples/login' }"/>
            <sidebar-item :link="{ name: 'Register', path: '/examples/register' }"/>
            <sidebar-item :link="{ name: 'Lock', path: '/examples/lock' }"/>
            <sidebar-item :link="{ name: 'Timeline', path: '/examples/pages/timeline' }"/>
            <sidebar-item :link="{ name: 'Profile', path: '/examples/pages/user' }"/>


          </sidebar-item>

          <sidebar-item :link="{
                    name: 'Components',
                    icon: 'ni ni-ui-04 text-info'
                  }">
            <sidebar-item :link="{ name: 'Buttons', path: '/examples/components/buttons' }"/>
            <sidebar-item :link="{ name: 'Cards', path: '/examples/components/cards' }"/>
            <sidebar-item :link="{ name: 'Grid', path: '/examples/components/grid-system' }"/>
            <sidebar-item :link="{ name: 'Notifications', path: '/examples/components/notifications' }"/>
            <sidebar-item :link="{ name: 'Icons', path: '/examples/components/icons' }"/>
            <sidebar-item :link="{ name: 'Typography', path: '/examples/components/typography' }"/>

            <sidebar-item :link="{ name: 'Multi Level' }">
              <sidebar-item :link="{ name: 'Third level menu', path:'#!' }"/>
              <sidebar-item :link="{ name: 'Just another link', path:'#a' }"/>
              <sidebar-item :link="{ name: 'One last link', path:'#b' }"/>
            </sidebar-item>
          </sidebar-item>
          <sidebar-item :link="{
                          name: 'Forms',
                          icon: 'ni ni-single-copy-04 text-pink'
                        }">
            <sidebar-item :link="{ name: 'Elements', path: '/examples/forms/elements' }"/>
            <sidebar-item :link="{ name: 'Components', path: '/examples/forms/components' }"/>
            <sidebar-item :link="{ name: 'Validation', path: '/examples/forms/validation' }"/>
          </sidebar-item>

          <sidebar-item :link="{
                          name: 'Tables',
                          icon: 'ni ni-align-left-2 text-default'
                        }">
            <sidebar-item :link="{ name: 'Tables', path: '/examples/tables/regular' }"/>
            <sidebar-item :link="{ name: 'Sortable', path: '/examples/tables/sortable' }"/>
            <sidebar-item :link="{ name: 'Paginated Tables', path: '/examples/tables/paginated' }"/>
          </sidebar-item>

          <sidebar-item :link="{
                          name: 'Maps',
                          icon: 'ni ni-map-big text-primary'
                        }">
            <sidebar-item :link="{ name: 'Google', path: '/examples/maps/google' }"/>
            <sidebar-item :link="{ name: 'Vector', path: '/examples/maps/vector' }"/>
          </sidebar-item>
          <sidebar-item
            :link="{
              name: 'Widgets',
              icon: 'ni ni-archive-2 text-green',
              path: '/examples/widgets'
            }">
          </sidebar-item>
          <sidebar-item
            :link="{
              name: 'Charts',
              icon: 'ni ni-chart-pie-35 text-info',
              path: '/examples/charts'
            }">
          </sidebar-item>

          <sidebar-item
            :link="{
              name: 'Calendar',
              icon: 'ni ni-calendar-grid-58 text-red',
              path: '/examples/calendar'
            }">
          </sidebar-item>
        </b-navbar-nav>
      </template>

      <template slot="links-after">
        <hr class="my-3">
        <h6 class="navbar-heading p-0 text-muted">Documentation</h6>

        <b-nav class="navbar-nav mb-md-3">
          <b-nav-item
               href="https://www.creative-tim.com/learning-lab/bootstrap-vue/quick-start/argon-dashboard"
               >
              <i class="ni ni-spaceship"></i>
              <b-nav-text class="p-0">Getting started</b-nav-text>
          </b-nav-item>
          <b-nav-item href="https://www.creative-tim.com/learning-lab/bootstrap-vue/colors/argon-dashboard">
              <i class="ni ni-palette"></i>
              <b-nav-text class="p-0">Foundation</b-nav-text>
          </b-nav-item>
          <b-nav-item href="https://www.creative-tim.com/learning-lab/bootstrap-vue/avatar/argon-dashboard">
              <i class="ni ni-ui-04"></i>
              <b-nav-text class="p-0">Components</b-nav-text>
          </b-nav-item>
          <b-nav-item href="https://www.creative-tim.com/learning-lab/bootstrap-vue/charts/argon-dashboard">
              <i class="ni ni-chart-pie-35"></i>
              <b-nav-text class="p-0">Plugins</b-nav-text>
          </b-nav-item>
        </b-nav>
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
  /* eslint-disable no-new */
  import PerfectScrollbar from 'perfect-scrollbar';
  import 'perfect-scrollbar/css/perfect-scrollbar.css';

  function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  import DashboardNavbar from './DashboardNavbar.vue';
  import ContentFooter from './ContentFooter.vue';
  import DashboardContent from './Content.vue';
  import { FadeTransition } from 'vue2-transitions';

  export default {
    components: {
      DashboardNavbar,
      ContentFooter,
      DashboardContent,
      FadeTransition
    },
    methods: {
      initScrollbar() {
        let isWindows = navigator.platform.startsWith('Win');
        if (isWindows) {
          initScrollbar('sidenav');
        }
      }
    },
    mounted() {
      this.initScrollbar()
    }
  };
</script>
<style lang="scss">
</style>

<template>
  <container-wrapper>
    <template slot="title">
      Research: Results
    </template>
    <b-card header="Keywords">
      <template v-slot:header>
        Keywords
        <b-button v-if="selectedItems.length > 0" class="pull-right" size="sm" variant="primary"
                  @click="importSelected">+ Add {{ selectedItems.length }} Keywords
        </b-button>
        <b-button class="pull-right mr-3" size="sm" variant="outline-primary" @click="exportAll">
          <font-awesome-icon :icon="['fas', 'download']"/>
          Export All
        </b-button>
      </template>
      <b-card-text>
        <b-check v-model="hideExisting">Hide existing keywords?</b-check>
        <b-table
          thead-tr-class="thead-light"
          :responsive="true"
          sortable
          striped
          :busy="loading"
          :items="keywords"
          :fields="fields"
          no-local-sorting
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template v-slot:cell(keyword)="data">
            {{ data.item.keyword }}
            <b-badge href="#" size="sm" @click="data.toggleDetails" class="ml-1" variant="muted">
              <span v-if="data.detailsShowing"><font-awesome-icon icon="chevron-down"/></span>
              <span v-else><font-awesome-icon icon="chevron-up"/></span>
            </b-badge>
          </template>
          <template v-slot:cell(cpc)="data">
            {{ Number(data.item.cpc) | number('0,0.00') }}
          </template>

          <template v-slot:cell(search_volume)="data">
            {{ Number(data.item.search_volume) | number('0,0') }}
          </template>

          <template v-slot:cell(competition)="data">
            <b-progress :max="100" :value="data.item.competition * 100"></b-progress>
          </template>

          <!-- Checkboxes -->
          <template v-slot:head(index)="data">
            #
          </template>
          <template v-slot:cell(index)="data">
            <b-form-checkbox name="selected-items" v-model="selectedItems" :value="data.item">
            </b-form-checkbox>
          </template>
          <!-- End Checkboxes -->

          <template v-slot:row-details="row">
            <view-keyword-details :keyword-id="row.item.id"/>
          </template>
        </b-table>
        <base-pagination align="center" v-model="currentPage" size="sm" :total="meta.total" :per-page="meta.per_page"></base-pagination>
      </b-card-text>
    </b-card>
  </container-wrapper>
</template>

<script>
import ViewKeywordDetails from "./ViewKeywordDetails"
import ContainerWrapper from "@/views/Keywords/pages/Container"
import { Http } from "@services"

export default {
  data () {
    return {
      hideExisting: true,
      meta: {
        total: 0,
        per_page: 15
      },
      keywords: [],
      fields: [
        { key: 'index', class: 'th-fit p-1 align-middle' },
        { key: 'keyword', sortable: true, sortDirection: 'asc' },
        { key: 'search_volume', sortable: true, sortDirection: 'desc', class: 'th-fit' },
        { key: 'best_position', sortable: true, sortDirection: 'asc', class: 'th-fit' },
        { key: 'num_domains', sortable: true, sortDirection: 'desc', class: 'th-fit' },
        { key: 'cpc', sortable: false, class: 'th-fit' },
        { key: 'competition', sortable: false, class: 'th-fit align-middle' },
        { key: 'features', sortable: false, class: 'th-fit' },
      ],
      currentPage: 1,
      perPage: 50,
      loading: false,
      selectedItems: [],
      sortBy: 'num_domains',
      sortDesc: true
    }
  },
  watch: {
    currentPage: function (to, from) {
      if (to !== from) {
        this.debouncedGetData();
      }
    },
    hideExisting: function () {
      this.debouncedGetData();
    },
    sortBy: function () {
      this.debouncedGetData();
    },
    sortDesc: function () {
      this.debouncedGetData();
    }
  },
  components: {
    ContainerWrapper,
    ViewKeywordDetails
  },
  mounted () {
    this.getData();
    this.debouncedGetData = _.debounce(this.getData, 50);
  },
  computed: {
    exportUrl: function () {
      return `/keywords/projects/${ this.$route.params.project }/research/domain-intersection/export?domains=${ this.$route.params.domains }&hide_existing=${ this.hideExisting }&sort_by=${ this.sortBy }&sort_direction=${ this.sortDesc ? 'desc' : 'asc' }`
    }
  },
  methods: {
    exportAll: function() {
      const url = `/keywords/projects/${ this.$route.params.project }/research/domain-intersection/export?domains=${ this.$route.params.domains }&hide_existing=${ this.hideExisting }&sort_by=${ this.sortBy }&sort_direction=${ this.sortDesc ? 'desc' : 'asc' }`
      Http.download(url, 'research-keywords.csv');
    },
    getData: function () {
      this.loading = true;
      let url = `/keywords/projects/${ this.$route.params.project }/research/domain-intersection?page=${ this.currentPage }`
      Http.post(url, {
        domain_ids: this.$route.params.domains,
        hide_existing: this.hideExisting,
        sort_by: this.sortBy,
        sort_direction: this.sortDesc ? 'desc' : 'asc'
      })
        .then(({ data, meta }) => {
          this.keywords = data;
          this.meta = meta;
          this.loading = false;
        })
        .catch(errors => {
          this.loading = false;
          this.onFailed(errors);
        });
    },
    importSelected: function () {
      let keywords = _.map(this.selectedItems, 'keyword')
      this.$router.push({
        name: 'keywords.add',
        params: {
          inputKeywords: keywords
        }
      });
    }
  }
}
</script>

<script>
import { Bar } from 'vue-chartjs'
import _ from 'lodash'
export default {
    extends: Bar,
    props: {
        searchVolumes: {
            type: [Array],
            default: function () {
                return []
            }
        }
    },
    mounted () {
        // Overwriting base render method with actual data.
        this.renderChart({
            maintainAspectRatio: false,
            labels: this.labels,
            datasets: [
            {
                label: 'Search Volume',
                backgroundColor: '#42a2dc',
                data: _.map(this.data, 'count')
            }
            ]
        }, {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                yAxes: [{
                    gridLines: {
                        display: false
                    },
                    ticks: {
                        min: 0,
                        // max: this.yTicks.max,
                    }
                }]
            }
        })
    },
    computed: {
        data: function() {
          return _.reverse(this.searchVolumes.slice());
        },
        // a computed getter
        labels: function () {
            let labels = []
            let years = _.map(this.data, 'year')
            let months = _.map(this.data, 'month')
            let merged = _.zip(years, months);
            _.forEach(merged, function(value) {
                labels.push(_.join(value, '-'))
            })
            return labels
        }
    }
}
</script>

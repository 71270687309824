<template>
    <b-form @submit.prevent="form.submit()">
        <div class="alert alert-success" v-if="form.successful">
            Saved successfully!
        </div>
        <b-alert :show="form.errors.hasErrors()" variant="danger">There was an error processing your input, check the URLs below are valid.</b-alert>
        <b-form-group
            label="Urls"
            label-for="urls"
        >
            <b-form-textarea rows="8" placeholder="Add Comma or Newline Separated URLs" id="urls" v-model="form.urls" :class="{'is-invalid': form.errors.has('urls')}" />
            <span class="invalid-feedback" v-show="form.errors.has('urls')">
                    {{ form.errors.get('urls') }}
            </span>
        </b-form-group>

        <b-button type="submit" :disabled="form.busy" variant="primary">
            <b-spinner small v-if="form.busy"></b-spinner> Submit
        </b-button>
    </b-form>
</template>

<script>
  import { Form, Http } from "@services"
  export default {
      props: ['inputUrls'],
      data() {
        const initialData = {
          urls: this.inputUrls ? _.join(this.inputUrls, "\n") : ''
        };

        return {
            form: new Form(initialData, async(form) => {
              const formData = Object.assign({}, form)
              formData.urls = formData.urls.split("\n").filter(Boolean);
              const { data } = await Http.post(`/page-experience/projects/${this.$route.params.project}/urls`, formData);
              this.$emit('success', data);
            }, ({ errors }) => {
              this.$emit('failed', errors);
            })
        }
      }
  }
</script>

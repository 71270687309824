<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar>
      <template slot-scope="props" slot="links">
        <sidebar-item :link="{ name: 'Dashboard', path: '/dashboard', icon: 'ni ni-shop text-primary' }"></sidebar-item>
        <sidebar-item :link="{ name: 'Keywords', path: '/keywords', icon: 'ni ni-sound-wave text-primary' }"></sidebar-item>
        <sidebar-item :link="{ name: 'Page Experience', path: '/page-experience', icon: 'ni ni-chart-bar-32 text-warning' }"></sidebar-item>
        <sidebar-item v-can:administer
          :link="{
            name: 'Admin',
            icon: 'ni ni-badge text-danger',
          }"
        >
          <sidebar-item :link="{ name: 'Manage Users', path: '/admin/manage-users' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Examples', path: '/examples/dashboard' }"></sidebar-item>

          <b-nav-item
            href="https://cortex-api.3whitehats.com/vapor-ui/"
          >
            <span class="nav-link-text">Vapor UI</span>
          </b-nav-item>
        </sidebar-item>
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
  /* eslint-disable no-new */
  import PerfectScrollbar from 'perfect-scrollbar';
  import 'perfect-scrollbar/css/perfect-scrollbar.css';

  function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  import DashboardNavbar from './DashboardNavbar.vue';
  import ContentFooter from './ContentFooter.vue';
  import DashboardContent from './Content.vue';
  import { FadeTransition } from 'vue2-transitions';
  import Content from "@/view_examples/Layout/Content"

  export default {
    components: {
      Content,
      DashboardNavbar,
      ContentFooter,
      DashboardContent,
      FadeTransition
    },
    methods: {
      initScrollbar() {
        let isWindows = navigator.platform.startsWith('Win');
        if (isWindows) {
          initScrollbar('sidenav');
        }
      }
    },
    mounted() {
      this.initScrollbar()
    }
  };
</script>
<style lang="scss">
</style>

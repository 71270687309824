<template>
  <container-wrapper>
    <template slot="title">
      Projects
    </template>
    <b-card no-body>
      <template v-slot:header>
        Projects
        <b-button class="float-right" size="sm" variant="primary" :to="{ name: 'pageexperience.projects.add'}">+ Add</b-button>
      </template>

      <div class="mx-4 my-2">
        <b-form inline>
          <label class="mr-sm-2 small" for="inline-form-custom-select-pref">Filter by Client</label>
          <b-form-select
            id="inline-form-custom-select-pref"
            class="mb-2 mr-sm-2 mb-sm-0"
            :options="clients"
            v-model="client_id"
            size="sm"
          ></b-form-select>
          <b-button variant="outline-primary" size="sm" v-if="client_id" @click="client_id=null">Reset Filters</b-button>
        </b-form>
      </div>

      <b-table thead-tr-class="thead-light" striped hover :items="items" :fields="fields" :busy="loading">
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template v-slot:cell(name)="data">
          <router-link :to="{ name: 'pageexperience.projects.index', params: { project: data.item.id }}">
            {{ data.item.name }}
          </router-link>
        </template>
        <template v-slot:cell(options)="data">
          <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret right>
            <template v-slot:button-content>
              <font-awesome-icon :icon="['fas', 'cog']"/>
              <span class="sr-only">Settings</span>
            </template>
            <b-dropdown-item class="small" :to="{ name:'pageexperience.projects.edit', params: { project: data.item.id } }">Edit
            </b-dropdown-item>
            <b-dropdown-item-button class="small" variant="danger" @click="deleteProject(data.item)">
              <font-awesome-icon :icon="['fas', 'trash']"/>
              Delete
            </b-dropdown-item-button>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>
  </container-wrapper>
</template>

<script>

import ContainerWrapper from '@/views/Keywords/pages/Container';
import { Http } from '@services';

export default {
  name: 'Projects',
  components: { ContainerWrapper },
  data() {
    return {
      fields: [
        'name',
        'options'
      ],
      items: [],
      client_id: this.$route.query.client_id || null,
      clients: [ { value: null, text: 'Select Client'}],
    };
  },
  watch: {
    "client_id": function() {
      this.loadItems();
    }
  },
  async mounted() {
    await this.loadItems();
    const { data } = await Http.get('/clients');
    _.forEach(data, (client) => {
      this.clients.push({
        value: client.id,
        text: client.name
      });
    });
  },
  methods: {
    async loadItems() {
      this.loading = true;
      const { data } = await Http.get(`/page-experience/projects?client_id=${this.client_id || ''}`);
      this.items = data;
      this.loading = false;
    },
    async deleteProject(project) {
      await Http.delete(`/page-experience/projects/${project.id}`)
      await this.loadItems();
    },
  },
};
</script>

<style scoped>

</style>

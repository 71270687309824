// Layouts
import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import AuthLayout from '@/views/Layout/AuthLayout.vue';

// GeneralViews
import NotFound from '@/views/NotFoundPage.vue';
import KeywordsRoutes from './modules/keywords'
import PageExperienceRoutes from './modules/pageexperience'
import LighthouseReport from '@/views/PageExperience/LighthouseReport';

const ManageUsers = () => import(/* webpackChunkName: "pages" */ '@/views/Admin/ManageUsers.vue');

// Pages
const ForgotPassword = () => import(/* webpackChunkName: "pages" */ '@/views/Auth/ForgotPassword.vue');
const ResetPassword = () => import(/* webpackChunkName: "pages" */ '@/views/Auth/ResetPassword.vue');
const AcceptInvite = () => import(/* webpackChunkName: "pages" */ '@/views/Auth/AcceptInvite.vue');

const Login = () => import(/* webpackChunkName: "pages" */ '@/views/Auth/Login.vue');
const Home = () => import(/* webpackChunkName: "pages" */ '@/views/Home.vue');
const Profile = () => import(/* webpackChunkName: "pages" */ '@/views/User/Profile.vue');

const EditClient = () => import(/* webpackChunkName: "pages" */ '@/views/Clients/EditClient.vue');
const AddClient = () => import(/* webpackChunkName: "pages" */ '@/views/Clients/AddClient.vue');

let authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Auth',
  children: [
    {
      path: 'login',
      name: 'Login',
      component: Login,
      props: true,
      meta: {
        redirectIfAuthed: true
      },
      //props: route => ({ message: route.query.message })
    },
    {
      path: 'auth/callback',
      name: 'OAuth Response',
      component: Login,
      meta: {
        redirectIfAuthed: true
      },
    },
    {
      path: 'auth/accept-invite/:token',
      name: 'Accept Invite',
      component: AcceptInvite,
    },
    {
      path: 'forgot-password',
      name: 'Forgot Password',
      component: ForgotPassword
    },
    {
      path: 'reset-password/:token',
      name: 'Reset Password',
      component: ResetPassword
    },
  ]
};

let userPages = {
  path: '/user',
  redirect: '/user/profile',
  component: DashboardLayout,
  name: 'User',
  meta: {
    requiresAuth: true
  },
  children: [
    {
      path: 'profile',
      name: 'Profile',
      component: Profile
    }
  ]
}

let adminPages = {
  path: '/admin',
  component: DashboardLayout,
  name: 'Admin',
  redirect: '/admin/manage-users',
  meta: {
    requiresAuth: true,
    can: 'administer'
  },
  children: [
    {
      path: 'manage-users',
      name: 'Manage Users',
      component: ManageUsers
    }
  ]
}

let keywordPages = {
  path: '/keywords',
  component: DashboardLayout,
  name: 'Keywords',
  redirect: '/keywords',
  meta: {
    requiresAuth: true
  },
  children: KeywordsRoutes
}

let pageExperiencePages = {
  path: '/page-experience',
  component: DashboardLayout,
  name: 'Page Experience',
  redirect: '/page-experience',
  meta: {
    requiresAuth: true
  },
  children: PageExperienceRoutes
}

let clientPages = {
  path: '/clients',
  component: DashboardLayout,
  name: 'Clients',
  redirect: '/dashboard',
  meta: {
    requiresAuth: true
  },
  children: [
    {
      path: 'add',
      name: 'Add Client',
      component: AddClient,
    },
    {
      path: 'edit/:client',
      name: 'Edit Client',
      component: EditClient,
    }
  ]
}

const routes = [
  {
    path: '/',
    redirect: '/login',
    name: 'Home'
  },
  {
    path: '/',
    component: DashboardLayout,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Home,
      }
    ]
  },
  userPages,
  authPages,
  adminPages,
  keywordPages,
  pageExperiencePages,
  {
    name: 'pageexperience.url.report',
    path: '/page-experience/lighthouse/:url/:strategy',
    component: LighthouseReport
  },
  clientPages,
  { path: '*', component: NotFound }
];

export default routes;

<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top border-bottom navbar-expand"
    :class="{'bg-default navbar-dark': type === 'default'}"
  >
    <!-- Navbar links -->
<!--    <b-navbar-nav class=" align-items-center font-weight-600">-->
<!--      <b-nav-item :to="{ path: '/'}">-->
<!--        Home-->
<!--      </b-nav-item>-->
<!--      <b-nav-item :to="{ path: '/lock'}">-->
<!--        Test 2-->
<!--      </b-nav-item>-->
<!--      &lt;!&ndash; This item dont have <b-nav-item> because they add class 'nav-link' which is not needed here &ndash;&gt;-->
<!--    </b-navbar-nav>-->
    <b-navbar-nav class="align-items-center mr-md-auto">
      <li class="nav-item d-xl-none ml-auto">
        <!-- Sidenav toggler -->
        <div class="pr-3 sidenav-toggler"
             :class="{active: !$sidebar.isMinimized, 'sidenav-toggler-dark': type === 'default', 'sidenav-toggler-light': type === 'light'}"
             @click="minimizeSidebar">
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </div>
      </li>
    </b-navbar-nav>
    <dashboard-nav-user-dropdown/>
  </base-nav>
</template>
<script>
import {BaseNav} from '@/components';
import DashboardNavUserDropdown from "@/views/Layout/Navbar/DashboardNavUserDropdown"
//import DashboardNavAppDropdown from "./Nav/DashboardNavAppDropdown"
//import DashboardNavNotifications from "@/view_examples/Layout/DashboardNavNotifications"

export default {
  components: {
    DashboardNavUserDropdown,
    //DashboardNavNotifications,
    //DashboardNavAppDropdown,
    BaseNav
  },
  props: {
    type: {
      type: String,
      default: 'default', // default|light
      description: 'Look of the dashboard navbar. Default (Green) or light (gray)'
    }
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    }
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: ''
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    }
  }
};
</script>

import Homepage from '@/views/Keywords/pages/Homepage'
import Project from '@/views/Keywords/pages/Project'
import AddKeywords from "@/views/Keywords/pages/keywords/AddKeywords"
import CreateProject from "@/views/Keywords/pages/projects/CreateProject"
import EditProject from "@/views/Keywords/pages/projects/EditProject"
import SelectDomains from "@/views/Keywords/pages/research/SelectDomains"
import ViewKeywords from "@/views/Keywords/pages/research/ViewKeywords"
import AddDomains from "@/views/Keywords/pages/keywords/AddDomains"

export default [
    {
      path: '',
      name: 'index',
      component: Homepage,
      meta: { title: 'Projects' }
    },
    {
        path: 'project/create',
        name: 'projects.create',
        component: CreateProject,
        meta: { title: 'Create Project' }
    },
    {
        path: 'project/edit/:project',
        name: 'projects.edit',
        component: EditProject,
        meta: { title: 'Edit Project' }
    },
    {
        path: 'project/:project',
        name: 'projects.index',
        component: Project,
        meta: { title: 'View Project' }
    },
    {
        path: 'project/:project/add-domains',
        name: 'domains.add',
        component: AddDomains,
        meta: { title: 'Add Domains' },
    },
    {
        path: 'project/:project/add-keywords',
        name: 'keywords.add',
        component: AddKeywords,
        meta: { title: 'Add Keywords' },
        props: true // Allow keywords to be passed to this component.
    },
    {
        path: 'project/:project/research/domain-keywords',
        name: 'research.select-domains',
        component: SelectDomains,
        meta: { title: 'Select Domains' }
    },
    {
        path: 'project/:project/research/domain-keywords/view-keywords/:domains',
        name: 'research.view-keywords',
        component: ViewKeywords,
        meta: { title: 'View Keywords' }
    }
]

import swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';

export default class {

  constructor(options = {}) {
    const defaultOptions = {
      confirmButtonText: 'Ok',
      buttonsStyling: false,
      showCloseButton: true
    };

    const customClassDefaults = {
      confirmButton: 'btn btn-warning',
      cancelButton: 'btn btn-secondary'
    };

    this.options = { ...defaultOptions, ...options };
    this.options.customClass = { ...customClassDefaults, ...options.customClass };
  }

  cancellable(show = true) {
    this.options.showCancelButton = show;
    return this;
  }

  fire(successCallback = null, cancelCallback = null) {
    return swal.fire(this.options).then((result) => {
      if (result.isConfirmed) {
        if (successCallback) {
          successCallback(result);
        }
      } else if (result.isDismissed) {
        if (cancelCallback) {
          cancelCallback(result);
        }
      }
    })
  }

  /*
   * Static constructors
   */

  static confirm(confirmedCallback, options = {}) {
    const confirmOptionsDefault = {
      title: 'Are you sure?',
      text: 'You may not be able to reverse this action.',
      confirmButtonText: 'Yes!'
    }

    return this.warning({...confirmOptionsDefault, ...options }).cancellable().fire(() => {
      confirmedCallback();
    })
  }

  static warning(options = {}) {
    const defaultOptions = {
      icon: 'warning',
      customClass: {
        confirmButton: 'btn btn-warning'
      }
    };

    return new this({ ...defaultOptions, ...options });
  }

  static success(options = {}) {
    const defaultOptions = {
      icon: 'success',
      customClass: {
        confirmButton: 'btn btn-success'
      }
    };

    return new this({ ...defaultOptions, ...options });
  }

  static question(options = {}) {
    const defaultOptions = {
      icon: 'question',
      customClass: {
        confirmButton: 'btn btn-primary'
      }
    };

    return new this({ ...defaultOptions, ...options });
  }

  static error(options = {}) {
    const defaultOptions = {
      icon: 'error',
      customClass: {
        confirmButton: 'btn btn-danger'
      }
    };

    return new this({ ...defaultOptions, ...options });
  }

  static info(options = {}) {
    const defaultOptions = {
      icon: 'info',
      customClass: {
        confirmButton: 'btn btn-info'
      }
    };

    return new this({ ...defaultOptions, ...options });
  }
}

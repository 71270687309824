import { User } from "@models"
import { Http } from "@services"

export default {
  namespaced: true,

  state: {
    authenticated: false,
    user: null
  },

  getters: {
    authenticated (state) {
      return state.authenticated
    },

    user (state) {
      return state.user
    },
  },

  mutations: {
    SET_AUTHENTICATED (state, value) {
      state.authenticated = value
    },

    SET_USER (state, value) {
      state.user = value
    }
  },

  actions: {
    async login ({ dispatch }, credentials) {
      await Http.get('/sanctum/csrf-cookie')
      await Http.post('/login', credentials)

      return dispatch('refreshUser')
    },

    async logout ({ dispatch }) {
      await Http.post('/logout')

      return await dispatch('refreshUser')
    },

    async refreshUser ({ commit }) {
      const user = await User.me();

      if (!user) {
        commit('SET_AUTHENTICATED', false)
        commit('SET_USER', null)
      } else {
        commit('SET_AUTHENTICATED', true)
        commit('SET_USER', user);
      }

    }
  }
}

import DashboardLayout from '@/view_examples/Layout/DashboardLayout.vue';
import AuthLayout from '@/view_examples/Pages/AuthLayout.vue';
// GeneralViews
import NotFound from '@/view_examples/GeneralViews/NotFoundPage.vue';

// Calendar
const Calendar = () => import(/* webpackChunkName: "extra" */ '@/view_examples/Calendar/Calendar.vue');
// Charts
const Charts = () => import(/* webpackChunkName: "dashboard" */ '@/view_examples/Charts.vue');

// Components pages
const Buttons = () => import(/* webpackChunkName: "components" */ '@/view_examples/Components/Buttons.vue');
const Cards = () => import(/* webpackChunkName: "components" */ '@/view_examples/Components/Cards.vue');
const GridSystem = () => import(/* webpackChunkName: "components" */ '@/view_examples/Components/GridSystem.vue');
const Notifications = () => import(/* webpackChunkName: "components" */ '@/view_examples/Components/Notifications.vue');
const Icons = () => import(/* webpackChunkName: "components" */ '@/view_examples/Components/Icons.vue');
const Typography = () => import(/* webpackChunkName: "components" */ '@/view_examples/Components/Typography.vue');

// Dashboard pages
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '@/view_examples/Dashboard/Dashboard.vue');
const AlternativeDashboard = () => import(/* webpackChunkName: "dashboard" */ '@/view_examples/Dashboard/AlternativeDashboard.vue');
const Widgets = () => import(/* webpackChunkName: "dashboard" */ '@/view_examples/Widgets.vue');

// Forms pages
const FormElements = () => import(/* webpackChunkName: "forms" */ '@/view_examples/Forms/FormElements.vue');
const FormComponents = () => import(/* webpackChunkName: "forms" */ '@/view_examples/Forms/FormComponents.vue');
const FormValidation = () => import(/* webpackChunkName: "forms" */ '@/view_examples/Forms/FormValidation.vue');

// Maps pages
const GoogleMaps = () => import(/* webpackChunkName: "extra" */ '@/view_examples/Maps/GoogleMaps.vue');
const VectorMaps = () => import(/* webpackChunkName: "extra" */ '@/view_examples/Maps/VectorMaps.vue');

// Pages
const User = () => import(/* webpackChunkName: "pages" */ '@/view_examples/Pages/UserProfile.vue');
const Pricing = () => import(/* webpackChunkName: "pages" */ '@/view_examples/Pages/Pricing.vue');
const TimeLine = () => import(/* webpackChunkName: "pages" */ '@/view_examples/Pages/TimeLinePage.vue');
const Login = () => import(/* webpackChunkName: "pages" */ '@/view_examples/Pages/Login.vue');
const Home = () => import(/* webpackChunkName: "pages" */ '@/view_examples/Pages/Home.vue');
const Register = () => import(/* webpackChunkName: "pages" */ '@/view_examples/Pages/Register.vue');
const Lock = () => import(/* webpackChunkName: "pages" */ '@/view_examples/Pages/Lock.vue');

// TableList pages
const RegularTables = () => import(/* webpackChunkName: "tables" */ '@/view_examples/Tables/RegularTables.vue');
const SortableTables = () => import(/* webpackChunkName: "tables" */ '@/view_examples/Tables/SortableTables.vue');
const PaginatedTables = () => import(/* webpackChunkName: "tables" */ '@/view_examples/Tables/PaginatedTables.vue');
let componentsMenu = {
  path: '/examples/components',
  component: DashboardLayout,
  redirect: '/examples/components/buttons',
  name: 'Old Components',
  children: [
    {
      path: 'buttons',
      name: 'Old Buttons',
      component: Buttons
    },
    {
      path: 'cards',
      name: 'Old Cards',
      component: Cards
    },
    {
      path: 'grid-system',
      name: 'Old Grid System',
      component: GridSystem
    },
    {
      path: 'notifications',
      name: 'Old Notifications',
      component: Notifications
    },
    {
      path: 'icons',
      name: 'Old Icons',
      component: Icons
    },
    {
      path: 'typography',
      name: 'Old Typography',
      component: Typography
    }
  ]
};
let formsMenu = {
  path: '/examples/forms',
  component: DashboardLayout,
  redirect: '/examples/forms/elements',
  name: 'Old Forms',
  children: [
    {
      path: 'elements',
      name: 'Old Form elements',
      component:  FormElements
    },
    {
      path: 'components',
      name: 'Old Form components',
      component:  FormComponents
    },
    {
      path: 'validation',
      name: 'Old Form validation',
      component:  FormValidation
    }
  ]
};

let tablesMenu = {
  path: '/examples/tables',
  component: DashboardLayout,
  redirect: '/examples/tables/regular',
  name: 'Old Tables menu',
  children: [
    {
      path: 'regular',
      name: 'Old Tables',
      component: RegularTables
    },
    {
      path: 'sortable',
      name: 'Old Sortable',
      component: SortableTables
    },
    {
      path: 'paginated',
      name: 'Old Paginated Tables',
      component: PaginatedTables
    }
  ]
};

let mapsMenu = {
  path: '/examples/maps',
  component: DashboardLayout,
  name: 'Old Maps',
  redirect: '/examples/maps/google',
  children: [
    {
      path: 'google',
      name: 'Old Google Maps',
      component: GoogleMaps
    },
    {
      path: 'vector',
      name: 'Old Vector Map',
      component: VectorMaps
    }
  ]
};

let pagesMenu = {
  path: '/examples/pages',
  component: DashboardLayout,
  name: 'Old Pages',
  redirect: '/examples/pages/user',
  children: [
    {
      path: 'user',
      name: 'Old User Page',
      component: User
    },
    {
      path: 'timeline',
      name: 'Old Timeline Page',
      component: TimeLine
    }
  ]
};

let authPages = {
  path: '/examples',
  component: AuthLayout,
  name: 'Old Authentication',
  children: [
    {
      path: 'home',
      name: 'Old Home',
      component: Home,
      meta: {
        noBodyBackground: true
      }
    },
    {
      path: 'login',
      name: 'Old Login',
      component: Login
    },
    {
      path: 'register',
      name: 'Old Register',
      component: Register
    },
    {
      path: 'pricing',
      name: 'Old Pricing',
      component: Pricing
    },
    {
      path: 'lock',
      name: 'Old Lock',
      component: Lock
    },
    { path: '*', component: NotFound }
  ]
};

const examplesRoutes = [
  {
    path: '/examples',
    redirect: '/examples/home',
    name: 'Old Home'
  },
  componentsMenu,
  formsMenu,
  tablesMenu,
  mapsMenu,
  pagesMenu,
  {
    path: '/examples',
    component: DashboardLayout,
    name: 'Old Dashboard layout',
    children: [
      {
        path: 'dashboard',
        name: 'Old Dashboard',
        component: Dashboard
      },
      {
        path: 'alternative',
        name: 'Old Alternative',
        component: AlternativeDashboard,
        meta: {
          navbarType: 'light'
        }
      },
      {
        path: 'calendar',
        name: 'Old Calendar',
        component: Calendar
      },
      {
        path: 'charts',
        name: 'Old Charts',
        component: Charts
      },
      {
        path: 'widgets',
        name: 'Old Widgets',
        component: Widgets
      }
    ]
  },
  authPages,
];

export default examplesRoutes;

import Projects from '@/views/PageExperience/Projects';
import Project from '@/views/PageExperience/Project';
import AddUrls from '@/views/PageExperience/AddUrls';
import CreateProject from '@/views/PageExperience/CreateProject';
import EditProject from '@/views/PageExperience/EditProject';

export default [
  {
    path: '',
    name: 'pageexperience.index',
    component: Projects,
    meta: { title: 'Projects' }
  },
  {
    path: 'project/:project',
    name: 'pageexperience.projects.index',
    component: Project
  },
  {
    path: 'project/:project/add-urls',
    name: 'pageexperience.projects.add-urls',
    component: AddUrls
  },
  {
    path: 'project/add',
    name: 'pageexperience.projects.add',
    component: CreateProject
  },
  {
    path: 'project/add/:project',
    name: 'pageexperience.projects.edit',
    component: EditProject
  }
]

import { Http } from '@services'
import Model from "./Model"
import store from "@/store"
import _  from "lodash"

export default class User extends Model {
  apiUrl (id) {
    return `/users/${id || this.id}`;
  }

  get initials() {
    const initials = this.name.match(/\b\w/g) || [];
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  }

  get role_name() {
    if (this.role === 'owner') {
      return 'Owner';
    }

    const roles = store.getters['global/roles'];
    if (roles[this.role]) {
      return roles[this.role].name;
    }

    return this.role;
  }

  hasPermission(permission) {
    return _.includes(this.permissions, '*') || _.includes(this.permissions, permission);
  }

  hasProfilePhoto() {
    return !!this.profile_photo_path;
  }

  isOwner() {
    return this.role === 'owner';
  }

  async delete () {
    await Http.delete(this.apiUrl())
  }

  async removeProfilePhoto(data) {
    await Http.delete('/user/profile-photo');
    await store.dispatch('auth/refreshUser');
  }

  async updateProfileInformation (data) {
    let formData = new FormData();
    formData.append('name', data.name);
    formData.append('email', data.email);
    formData.append('_method', 'PUT');

    let config = {};
    if (data.file) {
      formData.append('photo', data.file);
      config = {
        headers: { 'Content-Type': 'multipart/form-data' }
      };
    }

    await Http.post('/user/profile-information', formData, config);
    await store.dispatch('auth/refreshUser');
  }

  static async me() {
    try {
      const data = await Http.get('/user');
      return this.make(data);
    } catch (err) {
      const { response } = err;
      if (response.status === 401 || response.status === 403) {
        return null;
      }

      throw err;
    }
  }

}

import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import Form from './Form'
import Vue from 'vue'
import FileDownload from "./FileDownload"

axios.defaults.withCredentials = true
axios.defaults.headers.common = {
  'X-Requested-With': 'XMLHttpRequest',
  'X-Inertia': true,
  'Content-Type': 'application/json',
  'Accept': 'application/json',
}

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_BASE_URL || "https://cortex-api.3whitehats.com";

// Refresh CSRF token cookie when needed.
const refreshAuthLogic = () => axios.get('/sanctum/csrf-cookie').then(() => Promise.resolve() );
createAuthRefreshInterceptor(axios, refreshAuthLogic, {
  statusCodes: [ 419 ]
});

axios.interceptors.response.use(
  response => response,
  function errorResponseHandler(error) {
    if(error.response.status === 500) {
      const vm = new Vue()
      vm.$bvToast.toast('An unknown error occurred, please report what you were doing when this error occurred.', {
        title: 'Error',
        variant: 'danger',
        solid: true
      })
    }
    return Promise.reject(error)
  }
);

export default {
  async get (url, config = {}) {
    return this.send('get', url, {}, config)
  },

  async post (url, data = {}, config = {}) {
    if (data instanceof Form) {
      data = data.export()
    }
    return this.send('post', url, data, config)
  },

  async put (url, data = {}, config = {}) {
    return this.send('put', url, data, config)
  },

  async patch (url, data = {}, config = {}) {
    return this.send('patch', url, data, config)
  },

  async delete (url, data = {}, config = {}) {
    return this.send('delete', url, data, config)
  },

  async send (method, url, data = {}, config = {}) {
    const response = await axios({ method, url, data, ...config })
    return response.data
  },

  async download(url, filename = 'file.csv', config = {}) {
    const data  = await this.get(url, { responseType: 'blob', ...config });
    return FileDownload(data, filename);
  }
}

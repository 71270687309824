<template>
    <span class="m-auto font-weight-bold" :class="[this.size === 'xl' ? 'text-xl' : 'text-lg' ]">
      <b-badge v-if="score !== null && score !== undefined" variant="default" v-bind:style="{ backgroundColor: backgroundColor}">{{ score | roundNum }}</b-badge>
    </span>
</template>

<script>
export default {
  props: ['value', 'model', 'device', 'type', 'size'],
  computed: {
    backgroundColor: function() {
      const score = this.score;
      if (score >= 90) {
        return '#05CF6A';
      }

      if (score >= 50) {
        return '#FFA400';
      }

      return '#FF4E42';

    },
    score() {
      if (this.value) {
        return this.value;
      }

      const key = this.device === 'mobile' ? 'latest_mobile_result' : 'latest_desktop_result';
      return _.get(this.model, `${ key }.${ this.type }_score`);
    },
  },
  filters: {
    roundNum(value, accuracy, keep) {
      if (value === undefined || value === null) {
        return '-';
      }

      let fixed = Number(value).toFixed(accuracy);

      return keep ? fixed : +fixed;
    }
  }
};
</script>

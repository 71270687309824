<template>
    <b-form @submit.prevent="form.submit();">
        <div class="alert alert-success" v-if="form.successful">
            Saved successfully!
        </div>
        <b-alert :show="form.errors.hasErrors()" variant="danger">There was an error processing your input, see below.</b-alert>
        <b-form-group
            label="Domains"
            label-for="domains"
        >
            <b-form-textarea rows="8" placeholder="Add Comma or Newline Separated domains (no protocol or www)" id="keywords" v-model="form.domains" :class="{'is-invalid': form.errors.has('domains')}" />
            <span class="invalid-feedback" v-show="form.errors.has('domains')">
                    {{ form.errors.get('domains') }}
            </span>
        </b-form-group>

        <b-button type="submit" :disabled="form.busy" variant="primary">
            <b-spinner small v-if="form.busy"></b-spinner> Submit
        </b-button>
    </b-form>
</template>

<script>
import { Form, Http } from "@services"

    export default {
        data() {
          const initialData = {
            domains: ''
          };
          return {
              form: new Form(initialData, async (form) => {
                const url = `/keywords/projects/${this.$route.params.project}/domains`
                const { data } = await Http.post(url, form);
                this.$emit('success', data);
              }, ({ errors }) => {
                this.$emit('failed', errors);
              })
          }
        }
    }
</script>

import FormErrors from './FormErrors'
import { omit } from 'lodash'
export default class {

  constructor (initialData = {}, submitCallback = null, failureCallback = null) {
    this._initialData = initialData
    this._submitCallback = submitCallback
    this._failureCallback = failureCallback
    this.errors = new FormErrors()
    this.reset()
  }

  reset () {
    this.busy = false
    this.successful = false
    this.saved = false
    this.errors.clear()
    Object.assign(this, this._initialData)
  }

  async submit (...args) {
    if (this.busy || ! this._submitCallback) {
      return
    }
    this.beforeStart()
    const result = await this._submitCallback(this, ...args).catch(async error => {
      await this.onFailure(error)
      throw error
    })
    this.onSuccess()
    return result
  }

  export () {
    return omit({ ...this },
      ['_initialData', '_submitCallback', '_failureCallback', 'errors', 'busy', 'successful']);
  }

  beforeStart () {
    this.busy = true
    this.successful = false
    this.errors.clear()
  }

  onSuccess () {
    this.busy = false
    this.successful = true
    this.saved = true
    setTimeout(() => { this.saved = false }, 3000);
  }

  async onFailure (error) {
    this.busy = false
    if (error.response && error.response.data) {
      const { errors, message } = error.response.data

      if (this._failureCallback) {
        await this._failureCallback({errors, message, form: this, response: error.response});
      }

      this.setErrors(errors, message)
    }
  }

  setErrors (errors, message) {
    this.errors.set(errors, message)
  }
}
